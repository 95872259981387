//
// close.scss
// Extended from Bootstrap
//

.close {

  @include hover-focus {
    outline: none;
  }
}