//
// input-group.scss
//

// Sizing

.input-group-xs .form-control {
  height: calc(#{$input-btn-padding-y-xs * 2} + #{$input-btn-font-size-xs} * #{$line-height-base} + #{$input-border-width * 2});
  padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
  font-size: $input-btn-font-size-xs;
}

.input-group-xs .input-group-text {
  padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
  font-size: $input-btn-font-size-xs;
}
