//
// aos.scss
// Library overrides
//

[data-aos="img-skewed-item-left"],
[data-aos="img-skewed-item-right"] {
  opacity: 0;
  transition-property: opacity, transform, -webkit-transform;
  will-change: opacity, transform, -webkit-transform;
  backface-visibility: hidden;

  &.aos-animate {
    opacity: 1;
  }
}
[data-aos="img-skewed-item-left"] {
  transform: rotateY(-$img-skewed-rotate-y + 15deg) rotateX($img-skewed-rotate-x - 10deg) translate3d(0, 100px, 0);

  &.aos-animate {
    transform: rotateY(-$img-skewed-rotate-y) rotateX($img-skewed-rotate-x) translate3d(0, 0, 0);
  }
}
[data-aos="img-skewed-item-right"] {
  transform: rotateY($img-skewed-rotate-y - 15deg) rotateX($img-skewed-rotate-x - 10deg) translate3d(0, 100px, 0);

  &.aos-animate {
    transform: rotateY($img-skewed-rotate-y) rotateX($img-skewed-rotate-x) translate3d(0, 0, 0);
  }
}