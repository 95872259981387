// 
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

// Example of a variable override to change Landkit's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

$font-family-base: 'HKGroteskPro', sans-serif ;
$path-to-img: "../img";
$path-to-fonts: "/fonts";

$hectiq-blue: #354178;
$hectiq-blue-light: #99a4d2;

$navbar-light-color: $hectiq-blue ;
$navbar-light-hover-color: $hectiq-blue-light ;
$navbar-light-active-color: $hectiq-blue-light ;
$navbar-light-toggler-border-color: transparent ;

$small-font-size: 88%;