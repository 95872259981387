/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color:white; }

.hamburger-box {
  width: 40px;
  height: 30px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 10px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 35px;
    height: 3px;
    background-color: white;

    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }

  .dark-hamburger, .dark-hamburger::before, .dark-hamburger::after {
    background-color: #354178 ; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }
.container-xs {
  max-width: 500px; }

.container-sm {
  max-width: 900px; }

.container-md {
  max-width: 1200px; }

.container-lg {
  max-width: 1400px; }

.container-xl {
  max-width: 2000px; }

.container-xll {
  max-width: 2500px; }

.StepRangeSlider__tooltip {
  visibility: hidden !important; }

.StepRangeSlider__track {
  background-color: #f0f0f0;
  border: none;
  height: 8px; }

.StepRangeSlider__thumb {
  background-color: #bdbdbd;
  border: 2px solid white;
  width: 20px;
  height: 20px; }

.StepRangeSlider__thumb:active {
  background-color: #3182bd; }

/**
 * Custom variables followed by theme variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */
/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #869AB8;
  --gray-dark: #384C74;
  --primary: #335EEA;
  --secondary: #506690;
  --success: #42BA96;
  --info: #7C69EF;
  --warning: #FAD776;
  --danger: #DF4759;
  --light: #F9FBFD;
  --dark: #1B2A4E;
  --primary-desat: #6C8AEC;
  --black: #161C2D;
  --white: #FFFFFF;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(22, 28, 45, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "HKGroteskPro", sans-serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161C2D;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #335EEA;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #133bbd;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1.5rem 2rem;
  padding-bottom: 1.5rem 2rem;
  color: #869AB8;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1; }

h1, .h1 {
  font-size: 2.3125rem; }

h2, .h2 {
  font-size: 1.9375rem; }

h3, .h3 {
  font-size: 1.3125rem; }

h4, .h4 {
  font-size: 1.1875rem; }

h5, .h5 {
  font-size: 1.0625rem; }

h6, .h6 {
  font-size: 0.75rem; }

.lead {
  font-size: 1.3125rem;
  font-weight: 400; }

.display-1 {
  font-size: 4.375rem;
  font-weight: 400;
  line-height: 1; }

.display-2 {
  font-size: 3.8125rem;
  font-weight: 400;
  line-height: 1; }

.display-3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1; }

.display-4 {
  font-size: 2.6875rem;
  font-weight: 400;
  line-height: 1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #F1F4F8; }

small,
.small {
  font-size: 88%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.75rem;
  color: #869AB8; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #D9E2EF;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(22, 28, 45, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 88%;
  color: #869AB8; }

code {
  font-size: 0.8125rem;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.8125rem;
  color: #FFFFFF;
  background-color: #1B2A4E;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(22, 28, 45, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600;
    box-shadow: none; }

pre {
  display: block;
  font-size: 0.8125rem;
  color: #1B2A4E; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1040px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #161C2D;
  background-color: #FFFFFF; }
  .table th,
  .table td {
    padding: 1.5rem 2rem;
    vertical-align: top;
    border-top: 1px solid #F1F4F8; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #F1F4F8; }
  .table tbody + tbody {
    border-top: 2px solid #F1F4F8; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #F1F4F8; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #F1F4F8; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F9FBFD; }

.table-hover tbody tr:hover {
  color: #161C2D;
  background-color: rgba(22, 28, 45, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6d2f9; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95abf4; }

.table-hover .table-primary:hover {
  background-color: #afc0f7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #afc0f7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ced4e0; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a4afc5; }

.table-hover .table-secondary:hover {
  background-color: #bec6d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #bec6d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #caece2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9ddbc8; }

.table-hover .table-success:hover {
  background-color: #b7e5d8; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b7e5d8; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dad5fb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #bbb1f7; }

.table-hover .table-info:hover {
  background-color: #c5bef9; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c5bef9; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef4d9; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fceab8; }

.table-hover .table-warning:hover {
  background-color: #fdedc0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fdedc0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f6cbd1; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ee9fa9; }

.table-hover .table-danger:hover {
  background-color: #f2b5be; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f2b5be; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfefe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfdfe; }

.table-hover .table-light:hover {
  background-color: #ecf6f6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ecf6f6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfc3cd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8890a3; }

.table-hover .table-dark:hover {
  background-color: #b1b6c2; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b1b6c2; }

.table-primary-desat,
.table-primary-desat > th,
.table-primary-desat > td {
  background-color: #d6defa; }

.table-primary-desat th,
.table-primary-desat td,
.table-primary-desat thead th,
.table-primary-desat tbody + tbody {
  border-color: #b3c2f5; }

.table-hover .table-primary-desat:hover {
  background-color: #bfccf7; }
  .table-hover .table-primary-desat:hover > td,
  .table-hover .table-primary-desat:hover > th {
    background-color: #bfccf7; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #bebfc4; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #868992; }

.table-hover .table-black:hover {
  background-color: #b1b2b8; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #b1b2b8; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(22, 28, 45, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(14, 17, 28, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(14, 17, 28, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #384C74;
  border-color: #445d8e; }

.table .thead-light th {
  color: #161C2D;
  background-color: #FFFFFF;
  border-color: #F1F4F8; }

.table-dark {
  color: #FFFFFF;
  background-color: #384C74; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #445d8e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161C2D;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #F1F4F8;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #161C2D; }
  .form-control:focus {
    color: #161C2D;
    background-color: #FFFFFF;
    border-color: #a7b9f6;
    outline: 0; }
  .form-control::-webkit-input-placeholder {
    color: #869AB8;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #869AB8;
    opacity: 1; }
  .form-control::placeholder {
    color: #869AB8;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #F1F4F8;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #161C2D;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.8125rem + 1px);
  padding-bottom: calc(0.8125rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(1.122rem + 1px);
  padding-bottom: calc(1.122rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.6; }

.col-form-label-sm {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.6; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.8125rem 0;
  margin-bottom: 0;
  font-size: 1.0625rem;
  line-height: 1.6;
  color: #161C2D;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.6em + 1.125rem + 2px);
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.form-control-lg {
  height: calc(1.6em + 2.244rem + 2px);
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #869AB8; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 88%;
  color: #42BA96; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #FFFFFF;
  background-color: rgba(66, 186, 150, 0.9);
  border-radius: 0.375rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #42BA96; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #42BA96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #42BA96; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #42BA96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #42BA96; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #42BA96; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #42BA96; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #66c9ab;
  background-color: #66c9ab; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #42BA96; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #42BA96; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #42BA96;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 88%;
  color: #DF4759; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #FFFFFF;
  background-color: rgba(223, 71, 89, 0.9);
  border-radius: 0.375rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #DF4759; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #DF4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #DF4759; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #DF4759;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #DF4759; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DF4759; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #DF4759; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e77280;
  background-color: #e77280; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #DF4759; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DF4759; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #DF4759;
  box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 600;
  color: #161C2D;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #161C2D;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #335EEA;
  border-color: #335EEA;
  box-shadow: none; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #1746e0;
    border-color: #1643d4; }
  .btn-primary:focus, .btn-primary.focus {
    color: #FFFFFF;
    background-color: #1746e0;
    border-color: #1643d4;
    box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #335EEA;
    border-color: #335EEA; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1643d4;
    border-color: #153fc9; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5); }

.btn-secondary {
  color: #FFFFFF;
  background-color: #506690;
  border-color: #506690;
  box-shadow: none; }
  .btn-secondary:hover {
    color: #FFFFFF;
    background-color: #425577;
    border-color: #3e4f6f; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #FFFFFF;
    background-color: #425577;
    border-color: #3e4f6f;
    box-shadow: 0 0 0 0 rgba(106, 125, 161, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #506690;
    border-color: #506690; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #3e4f6f;
    border-color: #394967; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(106, 125, 161, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #42BA96;
  border-color: #42BA96;
  box-shadow: none; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #389e7f;
    border-color: #359478; }
  .btn-success:focus, .btn-success.focus {
    color: #FFFFFF;
    background-color: #389e7f;
    border-color: #359478;
    box-shadow: 0 0 0 0 rgba(94, 196, 166, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #42BA96;
    border-color: #42BA96; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #359478;
    border-color: #318b70; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(94, 196, 166, 0.5); }

.btn-info {
  color: #FFFFFF;
  background-color: #7C69EF;
  border-color: #7C69EF;
  box-shadow: none; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #5e46eb;
    border-color: #543bea; }
  .btn-info:focus, .btn-info.focus {
    color: #FFFFFF;
    background-color: #5e46eb;
    border-color: #543bea;
    box-shadow: 0 0 0 0 rgba(144, 128, 241, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #7C69EF;
    border-color: #7C69EF; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #543bea;
    border-color: #4a2fe9; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(144, 128, 241, 0.5); }

.btn-warning {
  color: #1B2A4E;
  background-color: #FAD776;
  border-color: #FAD776;
  box-shadow: none; }
  .btn-warning:hover {
    color: #1B2A4E;
    background-color: #f9cc51;
    border-color: #f8c945; }
  .btn-warning:focus, .btn-warning.focus {
    color: #1B2A4E;
    background-color: #f9cc51;
    border-color: #f8c945;
    box-shadow: 0 0 0 0 rgba(217, 189, 112, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #1B2A4E;
    background-color: #FAD776;
    border-color: #FAD776; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #1B2A4E;
    background-color: #f8c945;
    border-color: #f8c538; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(217, 189, 112, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #DF4759;
  border-color: #DF4759;
  box-shadow: none; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #d9263c;
    border-color: #cf2438; }
  .btn-danger:focus, .btn-danger.focus {
    color: #FFFFFF;
    background-color: #d9263c;
    border-color: #cf2438;
    box-shadow: 0 0 0 0 rgba(228, 99, 114, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #DF4759;
    border-color: #DF4759; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #cf2438;
    border-color: #c42235; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(228, 99, 114, 0.5); }

.btn-light {
  color: #1B2A4E;
  background-color: #F9FBFD;
  border-color: #F9FBFD;
  box-shadow: none; }
  .btn-light:hover {
    color: #1B2A4E;
    background-color: #dce8f3;
    border-color: #d3e2f0; }
  .btn-light:focus, .btn-light.focus {
    color: #1B2A4E;
    background-color: #dce8f3;
    border-color: #d3e2f0;
    box-shadow: 0 0 0 0 rgba(216, 220, 227, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #1B2A4E;
    background-color: #F9FBFD;
    border-color: #F9FBFD; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #1B2A4E;
    background-color: #d3e2f0;
    border-color: #c9dbed; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(216, 220, 227, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #1B2A4E;
  border-color: #1B2A4E;
  box-shadow: none; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #111b32;
    border-color: #0e1628; }
  .btn-dark:focus, .btn-dark.focus {
    color: #FFFFFF;
    background-color: #111b32;
    border-color: #0e1628;
    box-shadow: 0 0 0 0 rgba(61, 74, 105, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #1B2A4E;
    border-color: #1B2A4E; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0e1628;
    border-color: #0b111f; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(61, 74, 105, 0.5); }

.btn-primary-desat {
  color: #FFFFFF;
  background-color: #6C8AEC;
  border-color: #6C8AEC;
  box-shadow: none; }
  .btn-primary-desat:hover {
    color: #FFFFFF;
    background-color: #4a6fe8;
    border-color: #3f66e6; }
  .btn-primary-desat:focus, .btn-primary-desat.focus {
    color: #FFFFFF;
    background-color: #4a6fe8;
    border-color: #3f66e6;
    box-shadow: 0 0 0 0 rgba(130, 156, 239, 0.5); }
  .btn-primary-desat.disabled, .btn-primary-desat:disabled {
    color: #FFFFFF;
    background-color: #6C8AEC;
    border-color: #6C8AEC; }
  .btn-primary-desat:not(:disabled):not(.disabled):active, .btn-primary-desat:not(:disabled):not(.disabled).active,
  .show > .btn-primary-desat.dropdown-toggle {
    color: #FFFFFF;
    background-color: #3f66e6;
    border-color: #345de5; }
    .btn-primary-desat:not(:disabled):not(.disabled):active:focus, .btn-primary-desat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-desat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(130, 156, 239, 0.5); }

.btn-black {
  color: #FFFFFF;
  background-color: #161C2D;
  border-color: #161C2D;
  box-shadow: none; }
  .btn-black:hover {
    color: #FFFFFF;
    background-color: #090c13;
    border-color: #05070b; }
  .btn-black:focus, .btn-black.focus {
    color: #FFFFFF;
    background-color: #090c13;
    border-color: #05070b;
    box-shadow: 0 0 0 0 rgba(57, 62, 77, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #FFFFFF;
    background-color: #161C2D;
    border-color: #161C2D; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #FFFFFF;
    background-color: #05070b;
    border-color: #010102; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(57, 62, 77, 0.5); }

.btn-white {
  color: #1B2A4E;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  box-shadow: none; }
  .btn-white:hover {
    color: #1B2A4E;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #1B2A4E;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #1B2A4E;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #1B2A4E;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5); }

.btn-outline-primary {
  color: #335EEA;
  border-color: #335EEA; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #335EEA;
    border-color: #335EEA; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #335EEA;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #335EEA;
    border-color: #335EEA; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5); }

.btn-outline-secondary {
  color: #506690;
  border-color: #506690; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #506690;
    border-color: #506690; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #506690;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #506690;
    border-color: #506690; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5); }

.btn-outline-success {
  color: #42BA96;
  border-color: #42BA96; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #42BA96;
    border-color: #42BA96; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #42BA96;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #42BA96;
    border-color: #42BA96; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5); }

.btn-outline-info {
  color: #7C69EF;
  border-color: #7C69EF; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #7C69EF;
    border-color: #7C69EF; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #7C69EF;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #7C69EF;
    border-color: #7C69EF; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5); }

.btn-outline-warning {
  color: #FAD776;
  border-color: #FAD776; }
  .btn-outline-warning:hover {
    color: #1B2A4E;
    background-color: #FAD776;
    border-color: #FAD776; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FAD776;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #1B2A4E;
    background-color: #FAD776;
    border-color: #FAD776; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5); }

.btn-outline-danger {
  color: #DF4759;
  border-color: #DF4759; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #DF4759;
    border-color: #DF4759; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #DF4759;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #DF4759;
    border-color: #DF4759; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5); }

.btn-outline-light {
  color: #F9FBFD;
  border-color: #F9FBFD; }
  .btn-outline-light:hover {
    color: #1B2A4E;
    background-color: #F9FBFD;
    border-color: #F9FBFD; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F9FBFD;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #1B2A4E;
    background-color: #F9FBFD;
    border-color: #F9FBFD; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5); }

.btn-outline-dark {
  color: #1B2A4E;
  border-color: #1B2A4E; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #1B2A4E;
    border-color: #1B2A4E; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #1B2A4E;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1B2A4E;
    border-color: #1B2A4E; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5); }

.btn-outline-primary-desat {
  color: #6C8AEC;
  border-color: #6C8AEC; }
  .btn-outline-primary-desat:hover {
    color: #FFFFFF;
    background-color: #6C8AEC;
    border-color: #6C8AEC; }
  .btn-outline-primary-desat:focus, .btn-outline-primary-desat.focus {
    box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5); }
  .btn-outline-primary-desat.disabled, .btn-outline-primary-desat:disabled {
    color: #6C8AEC;
    background-color: transparent; }
  .btn-outline-primary-desat:not(:disabled):not(.disabled):active, .btn-outline-primary-desat:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-desat.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6C8AEC;
    border-color: #6C8AEC; }
    .btn-outline-primary-desat:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-desat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-desat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5); }

.btn-outline-black {
  color: #161C2D;
  border-color: #161C2D; }
  .btn-outline-black:hover {
    color: #FFFFFF;
    background-color: #161C2D;
    border-color: #161C2D; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #161C2D;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #FFFFFF;
    background-color: #161C2D;
    border-color: #161C2D; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5); }

.btn-outline-white {
  color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-outline-white:hover {
    color: #1B2A4E;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #FFFFFF;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #1B2A4E;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-link {
  font-weight: 400;
  color: #335EEA;
  text-decoration: none; }
  .btn-link:hover {
    color: #133bbd;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #869AB8;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 14rem;
  padding: 1.625rem 0;
  margin: 0 0 0;
  font-size: 0.9375rem;
  color: #161C2D;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0px solid rgba(22, 28, 45, 0.15);
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F1F4F8; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0 1.75rem;
  clear: both;
  font-weight: 400;
  color: #506690;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #335EEA;
    text-decoration: none;
    background-color: none; }
  .dropdown-item.active, .dropdown-item:active {
    color: #335EEA;
    text-decoration: none;
    background-color: none; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #869AB8;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 1.625rem 1.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #335EEA;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0 1.75rem;
  color: #506690; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: none; }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.8125rem 1.25rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #869AB8;
  text-align: center;
  white-space: nowrap;
  background-color: #FFFFFF;
  border: 1px solid #F1F4F8;
  border-radius: 0.375rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.6em + 2.244rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.122rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.6em + 1.125rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5625rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 3.25rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.35rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    border-color: #335EEA;
    background-color: #335EEA;
    box-shadow:; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none, none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a7b9f6; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #d5defb;
    border-color: #d5defb;
    box-shadow:; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #869AB8; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #F1F4F8; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.35rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #D9E2EF;
    border: #ABBCD5 solid 0px;
    box-shadow: none; }
  .custom-control-label::after {
    position: absolute;
    top: 0.35rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.375rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #335EEA;
  background-color: #335EEA;
  box-shadow:; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 94, 234, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(51, 94, 234, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 94, 234, 0.5); }

.custom-switch {
  padding-left: 3rem; }
  .custom-switch .custom-control-label::before {
    left: -3rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.35rem + 0px);
    left: calc(-3rem + 0px);
    width: 1rem;
    height: 1rem;
    background-color: #ABBCD5;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #D9E2EF;
    transform: translateX(1.5rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(51, 94, 234, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 3.25rem 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161C2D;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1.25rem center/auto 50%;
  border: 1px solid #F1F4F8;
  border-radius: 0.375rem;
  box-shadow: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #a7b9f6;
    outline: 0; }
    .custom-select:focus::-ms-value {
      color: #161C2D;
      background-color: #FFFFFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.25rem;
    background-image: none; }
  .custom-select:disabled {
    color: #869AB8;
    background-color: #F1F4F8; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #161C2D; }

.custom-select-sm {
  height: calc(1.6em + 1.125rem + 2px);
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  padding-left: 1rem;
  font-size: 1.0625rem; }

.custom-select-lg {
  height: calc(1.6em + 2.244rem + 2px);
  padding-top: 1.122rem;
  padding-bottom: 1.122rem;
  padding-left: 1.5rem;
  font-size: 1.0625rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #a7b9f6;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #F1F4F8; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #161C2D;
  background-color: #FFFFFF;
  border: 1px solid #F1F4F8;
  border-radius: 0.375rem;
  box-shadow: none; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 1.625rem);
    padding: 0.8125rem 1.25rem;
    line-height: 1.6;
    color: #506690;
    content: "Browse";
    background-color: #F1F4F8;
    border-left: inherit;
    border-radius: 0 0.375rem 0.375rem 0; }

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #335EEA;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #d5defb; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #D9E2EF;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #335EEA;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #d5defb; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #D9E2EF;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #335EEA;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(22, 28, 45, 0.1);
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #d5defb; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(22, 28, 45, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #D9E2EF;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #D9E2EF;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #ABBCD5; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #ABBCD5; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #ABBCD5; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #869AB8;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #D9E2EF; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #F1F4F8 #F1F4F8 #D9E2EF; }
    .nav-tabs .nav-link.disabled {
      color: #869AB8;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #506690;
    background-color: #FFFFFF;
    border-color: #D9E2EF #D9E2EF #FFFFFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.375rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #335EEA; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 0; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  margin-right: 0;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.1875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1.5rem;
      padding-left: 1.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #99a4d2; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #99a4d2; }

.navbar-light .navbar-nav .nav-link {
  color: #354178; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #99a4d2; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(22, 28, 45, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #99a4d2; }

.navbar-light .navbar-toggler {
  color: #354178;
  border-color: transparent; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23354178' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #354178; }
  .navbar-light .navbar-text a {
    color: #99a4d2; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #99a4d2; }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: #FFFFFF; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #FFFFFF; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: #FFFFFF;
  border-color: transparent; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: #FFFFFF; }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 0px solid #F1F4F8;
  border-radius: 0.375rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.375rem - 0px);
      border-top-right-radius: calc(0.375rem - 0px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.375rem - 0px);
      border-bottom-left-radius: calc(0.375rem - 0px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2rem; }

.card-title {
  margin-bottom: 1.5rem; }

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 2rem; }

.card-header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: rgba(22, 28, 45, 0.03);
  border-bottom: 0px solid #F1F4F8; }
  .card-header:first-child {
    border-radius: calc(0.375rem - 0px) calc(0.375rem - 0px) 0 0; }

.card-footer {
  padding: 1.5rem 2rem;
  background-color: rgba(22, 28, 45, 0.03);
  border-top: 0px solid #F1F4F8; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 0px) calc(0.375rem - 0px); }

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -1.5rem;
  margin-left: -1rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.375rem - 0px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.375rem - 0px);
  border-top-right-radius: calc(0.375rem - 0px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px); }

.card-deck .card {
  margin-bottom: 20px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 20px;
      margin-bottom: 0;
      margin-left: 20px; } }

.card-group > .card {
  margin-bottom: 20px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.5rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: 0px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 0.9375rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #506690;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #506690; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.8125rem 1.25rem;
  margin-left: -1px;
  line-height: 1.6;
  color: #335EEA;
  background-color: #FFFFFF;
  border: 1px solid #D9E2EF; }
  .page-link:hover {
    z-index: 2;
    color: #133bbd;
    text-decoration: none;
    background-color: #F1F4F8;
    border-color: #D9E2EF; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #FFFFFF;
  background-color: #335EEA;
  border-color: #335EEA; }

.page-item.disabled .page-link {
  color: #869AB8;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #D9E2EF; }

.pagination-lg .page-link {
  padding: 1.122rem 1.5rem;
  font-size: 1.1875rem;
  line-height: 1.3; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.pagination-sm .page-link {
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.3; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #335EEA; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFFFFF;
    background-color: #1643d4; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(51, 94, 234, 0.5); }

.badge-secondary {
  color: #FFFFFF;
  background-color: #506690; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFFFFF;
    background-color: #3e4f6f; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(80, 102, 144, 0.5); }

.badge-success {
  color: #FFFFFF;
  background-color: #42BA96; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFFFFF;
    background-color: #359478; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5); }

.badge-info {
  color: #FFFFFF;
  background-color: #7C69EF; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFFFFF;
    background-color: #543bea; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(124, 105, 239, 0.5); }

.badge-warning {
  color: #1B2A4E;
  background-color: #FAD776; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #1B2A4E;
    background-color: #f8c945; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(250, 215, 118, 0.5); }

.badge-danger {
  color: #FFFFFF;
  background-color: #DF4759; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFFFFF;
    background-color: #cf2438; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(223, 71, 89, 0.5); }

.badge-light {
  color: #1B2A4E;
  background-color: #F9FBFD; }
  a.badge-light:hover, a.badge-light:focus {
    color: #1B2A4E;
    background-color: #d3e2f0; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(249, 251, 253, 0.5); }

.badge-dark {
  color: #FFFFFF;
  background-color: #1B2A4E; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFFFFF;
    background-color: #0e1628; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(27, 42, 78, 0.5); }

.badge-primary-desat {
  color: #FFFFFF;
  background-color: #6C8AEC; }
  a.badge-primary-desat:hover, a.badge-primary-desat:focus {
    color: #FFFFFF;
    background-color: #3f66e6; }
  a.badge-primary-desat:focus, a.badge-primary-desat.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(108, 138, 236, 0.5); }

.badge-black {
  color: #FFFFFF;
  background-color: #161C2D; }
  a.badge-black:hover, a.badge-black:focus {
    color: #FFFFFF;
    background-color: #05070b; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(22, 28, 45, 0.5); }

.badge-white {
  color: #1B2A4E;
  background-color: #FFFFFF; }
  a.badge-white:hover, a.badge-white:focus {
    color: #1B2A4E;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #F1F4F8;
  border-radius: 0.5rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 600; }

.alert-dismissible {
  padding-right: 4.09375rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #253e8f;
  background-color: #335eea;
  border-color: #335eea; }
  .alert-primary hr {
    border-top-color: #1c4ce8; }
  .alert-primary .alert-link {
    color: #1b2c66; }

.alert-secondary {
  color: #344260;
  background-color: #506690;
  border-color: #506690; }
  .alert-secondary hr {
    border-top-color: #475a80; }
  .alert-secondary .alert-link {
    color: #222b3f; }

.alert-success {
  color: #2d6e64;
  background-color: #42ba96;
  border-color: #42ba96; }
  .alert-success hr {
    border-top-color: #3ba787; }
  .alert-success .alert-link {
    color: #1e4a43; }

.alert-info {
  color: #4b4492;
  background-color: #7c69ef;
  border-color: #7c69ef; }
  .alert-info hr {
    border-top-color: #6852ed; }
  .alert-info .alert-link {
    color: #39346f; }

.alert-warning {
  color: #8d7d53;
  background-color: #fad776;
  border-color: #fad776; }
  .alert-warning hr {
    border-top-color: #f9d05d; }
  .alert-warning .alert-link {
    color: #6d6140; }

.alert-danger {
  color: #7f3244;
  background-color: #df4759;
  border-color: #df4759; }
  .alert-danger hr {
    border-top-color: #db3145; }
  .alert-danger .alert-link {
    color: #5a2430; }

.alert-light {
  color: #8c9099;
  background-color: #f9fbfd;
  border-color: #f9fbfd; }
  .alert-light hr {
    border-top-color: #e6eef7; }
  .alert-light .alert-link {
    color: #727680; }

.alert-dark {
  color: #19233e;
  background-color: #1b2a4e;
  border-color: #1b2a4e; }
  .alert-dark hr {
    border-top-color: #14203b; }
  .alert-dark .alert-link {
    color: #0a0e1a; }

.alert-primary-desat {
  color: #435590;
  background-color: #6c8aec;
  border-color: #6c8aec; }
  .alert-primary-desat hr {
    border-top-color: #5578e9; }
  .alert-primary-desat .alert-link {
    color: #33406d; }

.alert-black {
  color: #161c2d;
  background-color: #161c2d;
  border-color: #161c2d; }
  .alert-black hr {
    border-top-color: #0e111c; }
  .alert-black .alert-link {
    color: #05070b; }

.alert-white {
  color: #8f929a;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #757881; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.79688rem;
  background-color: #F1F4F8;
  border-radius: 0.375rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(22, 28, 45, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #335EEA;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem; }

.list-group-item-action {
  width: 100%;
  color: #506690;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #506690;
    text-decoration: none;
    background-color: #F9FBFD; }
  .list-group-item-action:active {
    color: #161C2D;
    background-color: #F1F4F8; }

.list-group-item {
  position: relative;
  display: block;
  padding: 1.5rem 2rem;
  background-color: #FFFFFF;
  border: 1px solid #F1F4F8; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #869AB8;
    pointer-events: none;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #335EEA;
    border-color: #335EEA; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #253e8f;
  background-color: #c6d2f9; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #253e8f;
    background-color: #afc0f7; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #253e8f;
    border-color: #253e8f; }

.list-group-item-secondary {
  color: #344260;
  background-color: #ced4e0; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #344260;
    background-color: #bec6d6; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #344260;
    border-color: #344260; }

.list-group-item-success {
  color: #2d6e64;
  background-color: #caece2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #2d6e64;
    background-color: #b7e5d8; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #2d6e64;
    border-color: #2d6e64; }

.list-group-item-info {
  color: #4b4492;
  background-color: #dad5fb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #4b4492;
    background-color: #c5bef9; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #4b4492;
    border-color: #4b4492; }

.list-group-item-warning {
  color: #8d7d53;
  background-color: #fef4d9; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #8d7d53;
    background-color: #fdedc0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #8d7d53;
    border-color: #8d7d53; }

.list-group-item-danger {
  color: #7f3244;
  background-color: #f6cbd1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f3244;
    background-color: #f2b5be; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7f3244;
    border-color: #7f3244; }

.list-group-item-light {
  color: #8c9099;
  background-color: #fdfefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #8c9099;
    background-color: #ecf6f6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #8c9099;
    border-color: #8c9099; }

.list-group-item-dark {
  color: #19233e;
  background-color: #bfc3cd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #19233e;
    background-color: #b1b6c2; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #19233e;
    border-color: #19233e; }

.list-group-item-primary-desat {
  color: #435590;
  background-color: #d6defa; }
  .list-group-item-primary-desat.list-group-item-action:hover, .list-group-item-primary-desat.list-group-item-action:focus {
    color: #435590;
    background-color: #bfccf7; }
  .list-group-item-primary-desat.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #435590;
    border-color: #435590; }

.list-group-item-black {
  color: #161c2d;
  background-color: #bebfc4; }
  .list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
    color: #161c2d;
    background-color: #b1b2b8; }
  .list-group-item-black.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #161c2d;
    border-color: #161c2d; }

.list-group-item-white {
  color: #8f929a;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #8f929a;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #8f929a;
    border-color: #8f929a; }

.close {
  float: right;
  font-size: 1.59375rem;
  font-weight: 600;
  line-height: 1;
  color: #869AB8;
  text-shadow: none;
  opacity: .5; }
  .close:hover {
    color: #869AB8;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(22, 28, 45, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #869AB8;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(22, 28, 45, 0.2);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(22, 28, 45, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #161C2D; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.8; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #F1F4F8;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.6; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.5rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 2.25rem;
  border-top: 1px solid #F1F4F8;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(22, 28, 45, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "HKGroteskPro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #161C2D; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #161C2D; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #161C2D; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #161C2D; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #161C2D;
  border-radius: 0.375rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 380px;
  font-family: "HKGroteskPro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9375rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 0.625rem;
    margin: 0 0.375rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.625rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.625rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.625rem 0.625rem 0;
      border-top-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.625rem 0.625rem 0;
      border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.625rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.625rem - 1px);
    width: 0.625rem;
    height: 1.25rem;
    margin: 0.375rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.625rem 0.625rem 0.625rem 0;
      border-right-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.625rem 0.625rem 0.625rem 0;
      border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.625rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.625rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.625rem 0.625rem 0.625rem;
      border-bottom-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.625rem 0.625rem 0.625rem;
      border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1.25rem;
    margin-left: -0.625rem;
    content: "";
    border-bottom: 1px solid transparent; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.625rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.625rem - 1px);
    width: 0.625rem;
    height: 1.25rem;
    margin: 0.375rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.625rem 0 0.625rem 0.625rem;
      border-left-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.625rem 0 0.625rem 0.625rem;
      border-left-color: #FFFFFF; }

.popover-header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  font-size: 1.0625rem;
  color: #335EEA;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1.5rem 2rem;
  color: #869AB8; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #335EEA !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1643d4 !important; }

.bg-secondary {
  background-color: #506690 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3e4f6f !important; }

.bg-success {
  background-color: #42BA96 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #359478 !important; }

.bg-info {
  background-color: #7C69EF !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #543bea !important; }

.bg-warning {
  background-color: #FAD776 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f8c945 !important; }

.bg-danger {
  background-color: #DF4759 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cf2438 !important; }

.bg-light {
  background-color: #F9FBFD !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d3e2f0 !important; }

.bg-dark {
  background-color: #1B2A4E !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0e1628 !important; }

.bg-primary-desat {
  background-color: #6C8AEC !important; }

a.bg-primary-desat:hover, a.bg-primary-desat:focus,
button.bg-primary-desat:hover,
button.bg-primary-desat:focus {
  background-color: #3f66e6 !important; }

.bg-black {
  background-color: #161C2D !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #05070b !important; }

.bg-white {
  background-color: #FFFFFF !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #F1F4F8 !important; }

.border-top {
  border-top: 1px solid #F1F4F8 !important; }

.border-right {
  border-right: 1px solid #F1F4F8 !important; }

.border-bottom {
  border-bottom: 1px solid #F1F4F8 !important; }

.border-left {
  border-left: 1px solid #F1F4F8 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #335EEA !important; }

.border-secondary {
  border-color: #506690 !important; }

.border-success {
  border-color: #42BA96 !important; }

.border-info {
  border-color: #7C69EF !important; }

.border-warning {
  border-color: #FAD776 !important; }

.border-danger {
  border-color: #DF4759 !important; }

.border-light {
  border-color: #F9FBFD !important; }

.border-dark {
  border-color: #1B2A4E !important; }

.border-primary-desat {
  border-color: #6C8AEC !important; }

.border-black {
  border-color: #161C2D !important; }

.border-white {
  border-color: #FFFFFF !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important; }

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-lg {
  border-radius: 0.5rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(22, 28, 45, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important; }

.shadow-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-110 {
  width: 110% !important; }

.w-120 {
  width: 120% !important; }

.w-130 {
  width: 130% !important; }

.w-140 {
  width: 140% !important; }

.w-150 {
  width: 150% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-110 {
  height: 110% !important; }

.h-120 {
  height: 120% !important; }

.h-130 {
  height: 130% !important; }

.h-140 {
  height: 140% !important; }

.h-150 {
  height: 150% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 0.75rem !important; }

.mt-3,
.my-3 {
  margin-top: 0.75rem !important; }

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important; }

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important; }

.m-4 {
  margin: 1rem !important; }

.mt-4,
.my-4 {
  margin-top: 1rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1rem !important; }

.m-5 {
  margin: 1.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 1.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 1.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 1.5rem !important; }

.m-6 {
  margin: 2rem !important; }

.mt-6,
.my-6 {
  margin-top: 2rem !important; }

.mr-6,
.mx-6 {
  margin-right: 2rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 2rem !important; }

.ml-6,
.mx-6 {
  margin-left: 2rem !important; }

.m-7 {
  margin: 2.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 2.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 2.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 2.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 2.5rem !important; }

.m-8 {
  margin: 3rem !important; }

.mt-8,
.my-8 {
  margin-top: 3rem !important; }

.mr-8,
.mx-8 {
  margin-right: 3rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 3rem !important; }

.ml-8,
.mx-8 {
  margin-left: 3rem !important; }

.m-9 {
  margin: 4rem !important; }

.mt-9,
.my-9 {
  margin-top: 4rem !important; }

.mr-9,
.mx-9 {
  margin-right: 4rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 4rem !important; }

.ml-9,
.mx-9 {
  margin-left: 4rem !important; }

.m-10 {
  margin: 5rem !important; }

.mt-10,
.my-10 {
  margin-top: 5rem !important; }

.mr-10,
.mx-10 {
  margin-right: 5rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 5rem !important; }

.ml-10,
.mx-10 {
  margin-left: 5rem !important; }

.m-11 {
  margin: 6rem !important; }

.mt-11,
.my-11 {
  margin-top: 6rem !important; }

.mr-11,
.mx-11 {
  margin-right: 6rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 6rem !important; }

.ml-11,
.mx-11 {
  margin-left: 6rem !important; }

.m-12 {
  margin: 8rem !important; }

.mt-12,
.my-12 {
  margin-top: 8rem !important; }

.mr-12,
.mx-12 {
  margin-right: 8rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 8rem !important; }

.ml-12,
.mx-12 {
  margin-left: 8rem !important; }

.m-13 {
  margin: 10rem !important; }

.mt-13,
.my-13 {
  margin-top: 10rem !important; }

.mr-13,
.mx-13 {
  margin-right: 10rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 10rem !important; }

.ml-13,
.mx-13 {
  margin-left: 10rem !important; }

.m-14 {
  margin: 12rem !important; }

.mt-14,
.my-14 {
  margin-top: 12rem !important; }

.mr-14,
.mx-14 {
  margin-right: 12rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 12rem !important; }

.ml-14,
.mx-14 {
  margin-left: 12rem !important; }

.m-15 {
  margin: 16rem !important; }

.mt-15,
.my-15 {
  margin-top: 16rem !important; }

.mr-15,
.mx-15 {
  margin-right: 16rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 16rem !important; }

.ml-15,
.mx-15 {
  margin-left: 16rem !important; }

.m-16 {
  margin: 25rem !important; }

.mt-16,
.my-16 {
  margin-top: 25rem !important; }

.mr-16,
.mx-16 {
  margin-right: 25rem !important; }

.mb-16,
.my-16 {
  margin-bottom: 25rem !important; }

.ml-16,
.mx-16 {
  margin-left: 25rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 0.75rem !important; }

.pt-3,
.py-3 {
  padding-top: 0.75rem !important; }

.pr-3,
.px-3 {
  padding-right: 0.75rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important; }

.pl-3,
.px-3 {
  padding-left: 0.75rem !important; }

.p-4 {
  padding: 1rem !important; }

.pt-4,
.py-4 {
  padding-top: 1rem !important; }

.pr-4,
.px-4 {
  padding-right: 1rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1rem !important; }

.pl-4,
.px-4 {
  padding-left: 1rem !important; }

.p-5 {
  padding: 1.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 1.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 1.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 1.5rem !important; }

.p-6 {
  padding: 2rem !important; }

.pt-6,
.py-6 {
  padding-top: 2rem !important; }

.pr-6,
.px-6 {
  padding-right: 2rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 2rem !important; }

.pl-6,
.px-6 {
  padding-left: 2rem !important; }

.p-7 {
  padding: 2.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 2.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 2.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 2.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 2.5rem !important; }

.p-8 {
  padding: 3rem !important; }

.pt-8,
.py-8 {
  padding-top: 3rem !important; }

.pr-8,
.px-8 {
  padding-right: 3rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 3rem !important; }

.pl-8,
.px-8 {
  padding-left: 3rem !important; }

.p-9 {
  padding: 4rem !important; }

.pt-9,
.py-9 {
  padding-top: 4rem !important; }

.pr-9,
.px-9 {
  padding-right: 4rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 4rem !important; }

.pl-9,
.px-9 {
  padding-left: 4rem !important; }

.p-10 {
  padding: 5rem !important; }

.pt-10,
.py-10 {
  padding-top: 5rem !important; }

.pr-10,
.px-10 {
  padding-right: 5rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 5rem !important; }

.pl-10,
.px-10 {
  padding-left: 5rem !important; }

.p-11 {
  padding: 6rem !important; }

.pt-11,
.py-11 {
  padding-top: 6rem !important; }

.pr-11,
.px-11 {
  padding-right: 6rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 6rem !important; }

.pl-11,
.px-11 {
  padding-left: 6rem !important; }

.p-12 {
  padding: 8rem !important; }

.pt-12,
.py-12 {
  padding-top: 8rem !important; }

.pr-12,
.px-12 {
  padding-right: 8rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 8rem !important; }

.pl-12,
.px-12 {
  padding-left: 8rem !important; }

.p-13 {
  padding: 10rem !important; }

.pt-13,
.py-13 {
  padding-top: 10rem !important; }

.pr-13,
.px-13 {
  padding-right: 10rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 10rem !important; }

.pl-13,
.px-13 {
  padding-left: 10rem !important; }

.p-14 {
  padding: 12rem !important; }

.pt-14,
.py-14 {
  padding-top: 12rem !important; }

.pr-14,
.px-14 {
  padding-right: 12rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 12rem !important; }

.pl-14,
.px-14 {
  padding-left: 12rem !important; }

.p-15 {
  padding: 16rem !important; }

.pt-15,
.py-15 {
  padding-top: 16rem !important; }

.pr-15,
.px-15 {
  padding-right: 16rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 16rem !important; }

.pl-15,
.px-15 {
  padding-left: 16rem !important; }

.p-16 {
  padding: 25rem !important; }

.pt-16,
.py-16 {
  padding-top: 25rem !important; }

.pr-16,
.px-16 {
  padding-right: 25rem !important; }

.pb-16,
.py-16 {
  padding-bottom: 25rem !important; }

.pl-16,
.px-16 {
  padding-left: 25rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -0.75rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important; }

.m-n4 {
  margin: -1rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1rem !important; }

.m-n5 {
  margin: -1.5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -1.5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -1.5rem !important; }

.m-n6 {
  margin: -2rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -2rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -2rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -2rem !important; }

.m-n7 {
  margin: -2.5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -2.5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -2.5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -2.5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -2.5rem !important; }

.m-n8 {
  margin: -3rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -3rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -3rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -3rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -3rem !important; }

.m-n9 {
  margin: -4rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -4rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important; }

.m-n10 {
  margin: -5rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -5rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -5rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -5rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -5rem !important; }

.m-n11 {
  margin: -6rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -6rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -6rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -6rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -6rem !important; }

.m-n12 {
  margin: -8rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -8rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -8rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -8rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -8rem !important; }

.m-n13 {
  margin: -10rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -10rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -10rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -10rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -10rem !important; }

.m-n14 {
  margin: -12rem !important; }

.mt-n14,
.my-n14 {
  margin-top: -12rem !important; }

.mr-n14,
.mx-n14 {
  margin-right: -12rem !important; }

.mb-n14,
.my-n14 {
  margin-bottom: -12rem !important; }

.ml-n14,
.mx-n14 {
  margin-left: -12rem !important; }

.m-n15 {
  margin: -16rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -16rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -16rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -16rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -16rem !important; }

.m-n16 {
  margin: -25rem !important; }

.mt-n16,
.my-n16 {
  margin-top: -25rem !important; }

.mr-n16,
.mx-n16 {
  margin-right: -25rem !important; }

.mb-n16,
.my-n16 {
  margin-bottom: -25rem !important; }

.ml-n16,
.mx-n16 {
  margin-left: -25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 0.75rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important; }
  .m-sm-4 {
    margin: 1rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1rem !important; }
  .m-sm-5 {
    margin: 1.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.5rem !important; }
  .m-sm-6 {
    margin: 2rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2rem !important; }
  .m-sm-7 {
    margin: 2.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 2.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 2.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 2.5rem !important; }
  .m-sm-8 {
    margin: 3rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3rem !important; }
  .m-sm-9 {
    margin: 4rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important; }
  .m-sm-10 {
    margin: 5rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem !important; }
  .m-sm-11 {
    margin: 6rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 6rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 6rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 6rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 6rem !important; }
  .m-sm-12 {
    margin: 8rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 8rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 8rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 8rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 8rem !important; }
  .m-sm-13 {
    margin: 10rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 10rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 10rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 10rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 10rem !important; }
  .m-sm-14 {
    margin: 12rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 12rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 12rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 12rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 12rem !important; }
  .m-sm-15 {
    margin: 16rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 16rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 16rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 16rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 16rem !important; }
  .m-sm-16 {
    margin: 25rem !important; }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 25rem !important; }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 25rem !important; }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 25rem !important; }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 25rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 0.75rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important; }
  .p-sm-4 {
    padding: 1rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1rem !important; }
  .p-sm-5 {
    padding: 1.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.5rem !important; }
  .p-sm-6 {
    padding: 2rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2rem !important; }
  .p-sm-7 {
    padding: 2.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 2.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 2.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 2.5rem !important; }
  .p-sm-8 {
    padding: 3rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3rem !important; }
  .p-sm-9 {
    padding: 4rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important; }
  .p-sm-10 {
    padding: 5rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem !important; }
  .p-sm-11 {
    padding: 6rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 6rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 6rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 6rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 6rem !important; }
  .p-sm-12 {
    padding: 8rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 8rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 8rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 8rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 8rem !important; }
  .p-sm-13 {
    padding: 10rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 10rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 10rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 10rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 10rem !important; }
  .p-sm-14 {
    padding: 12rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 12rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 12rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 12rem !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 12rem !important; }
  .p-sm-15 {
    padding: 16rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 16rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 16rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 16rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 16rem !important; }
  .p-sm-16 {
    padding: 25rem !important; }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 25rem !important; }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 25rem !important; }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 25rem !important; }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 25rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -0.75rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.75rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.75rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.75rem !important; }
  .m-sm-n4 {
    margin: -1rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1rem !important; }
  .m-sm-n5 {
    margin: -1.5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.5rem !important; }
  .m-sm-n6 {
    margin: -2rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2rem !important; }
  .m-sm-n7 {
    margin: -2.5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -2.5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -2.5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -2.5rem !important; }
  .m-sm-n8 {
    margin: -3rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3rem !important; }
  .m-sm-n9 {
    margin: -4rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important; }
  .m-sm-n10 {
    margin: -5rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -5rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -5rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -5rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -5rem !important; }
  .m-sm-n11 {
    margin: -6rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -6rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -6rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -6rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -6rem !important; }
  .m-sm-n12 {
    margin: -8rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -8rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -8rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -8rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -8rem !important; }
  .m-sm-n13 {
    margin: -10rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -10rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -10rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -10rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -10rem !important; }
  .m-sm-n14 {
    margin: -12rem !important; }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -12rem !important; }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -12rem !important; }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -12rem !important; }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -12rem !important; }
  .m-sm-n15 {
    margin: -16rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -16rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -16rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -16rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -16rem !important; }
  .m-sm-n16 {
    margin: -25rem !important; }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -25rem !important; }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -25rem !important; }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -25rem !important; }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 0.75rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important; }
  .m-md-4 {
    margin: 1rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1rem !important; }
  .m-md-5 {
    margin: 1.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.5rem !important; }
  .m-md-6 {
    margin: 2rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2rem !important; }
  .m-md-7 {
    margin: 2.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 2.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 2.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 2.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 2.5rem !important; }
  .m-md-8 {
    margin: 3rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3rem !important; }
  .m-md-9 {
    margin: 4rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important; }
  .m-md-10 {
    margin: 5rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem !important; }
  .m-md-11 {
    margin: 6rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 6rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 6rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 6rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 6rem !important; }
  .m-md-12 {
    margin: 8rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 8rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 8rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 8rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 8rem !important; }
  .m-md-13 {
    margin: 10rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 10rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 10rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 10rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 10rem !important; }
  .m-md-14 {
    margin: 12rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 12rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 12rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 12rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 12rem !important; }
  .m-md-15 {
    margin: 16rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 16rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 16rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 16rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 16rem !important; }
  .m-md-16 {
    margin: 25rem !important; }
  .mt-md-16,
  .my-md-16 {
    margin-top: 25rem !important; }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 25rem !important; }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 25rem !important; }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 25rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 0.75rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important; }
  .p-md-4 {
    padding: 1rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1rem !important; }
  .p-md-5 {
    padding: 1.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.5rem !important; }
  .p-md-6 {
    padding: 2rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2rem !important; }
  .p-md-7 {
    padding: 2.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 2.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 2.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 2.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 2.5rem !important; }
  .p-md-8 {
    padding: 3rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3rem !important; }
  .p-md-9 {
    padding: 4rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important; }
  .p-md-10 {
    padding: 5rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem !important; }
  .p-md-11 {
    padding: 6rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 6rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 6rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 6rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 6rem !important; }
  .p-md-12 {
    padding: 8rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 8rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 8rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 8rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 8rem !important; }
  .p-md-13 {
    padding: 10rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 10rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 10rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 10rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 10rem !important; }
  .p-md-14 {
    padding: 12rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 12rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 12rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 12rem !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 12rem !important; }
  .p-md-15 {
    padding: 16rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 16rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 16rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 16rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 16rem !important; }
  .p-md-16 {
    padding: 25rem !important; }
  .pt-md-16,
  .py-md-16 {
    padding-top: 25rem !important; }
  .pr-md-16,
  .px-md-16 {
    padding-right: 25rem !important; }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 25rem !important; }
  .pl-md-16,
  .px-md-16 {
    padding-left: 25rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -0.75rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.75rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.75rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.75rem !important; }
  .m-md-n4 {
    margin: -1rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1rem !important; }
  .m-md-n5 {
    margin: -1.5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.5rem !important; }
  .m-md-n6 {
    margin: -2rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2rem !important; }
  .m-md-n7 {
    margin: -2.5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -2.5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -2.5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -2.5rem !important; }
  .m-md-n8 {
    margin: -3rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3rem !important; }
  .m-md-n9 {
    margin: -4rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important; }
  .m-md-n10 {
    margin: -5rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -5rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -5rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -5rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -5rem !important; }
  .m-md-n11 {
    margin: -6rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -6rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -6rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -6rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -6rem !important; }
  .m-md-n12 {
    margin: -8rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -8rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -8rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -8rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -8rem !important; }
  .m-md-n13 {
    margin: -10rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -10rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -10rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -10rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -10rem !important; }
  .m-md-n14 {
    margin: -12rem !important; }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -12rem !important; }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -12rem !important; }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -12rem !important; }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -12rem !important; }
  .m-md-n15 {
    margin: -16rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -16rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -16rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -16rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -16rem !important; }
  .m-md-n16 {
    margin: -25rem !important; }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -25rem !important; }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -25rem !important; }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -25rem !important; }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 0.75rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important; }
  .m-lg-4 {
    margin: 1rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important; }
  .m-lg-5 {
    margin: 1.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.5rem !important; }
  .m-lg-6 {
    margin: 2rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2rem !important; }
  .m-lg-7 {
    margin: 2.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 2.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 2.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 2.5rem !important; }
  .m-lg-8 {
    margin: 3rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3rem !important; }
  .m-lg-9 {
    margin: 4rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important; }
  .m-lg-10 {
    margin: 5rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem !important; }
  .m-lg-11 {
    margin: 6rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 6rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 6rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 6rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 6rem !important; }
  .m-lg-12 {
    margin: 8rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 8rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 8rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 8rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 8rem !important; }
  .m-lg-13 {
    margin: 10rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 10rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 10rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 10rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 10rem !important; }
  .m-lg-14 {
    margin: 12rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 12rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 12rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 12rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 12rem !important; }
  .m-lg-15 {
    margin: 16rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 16rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 16rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 16rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 16rem !important; }
  .m-lg-16 {
    margin: 25rem !important; }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 25rem !important; }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 25rem !important; }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 25rem !important; }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 25rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 0.75rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important; }
  .p-lg-4 {
    padding: 1rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important; }
  .p-lg-5 {
    padding: 1.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.5rem !important; }
  .p-lg-6 {
    padding: 2rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2rem !important; }
  .p-lg-7 {
    padding: 2.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 2.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 2.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 2.5rem !important; }
  .p-lg-8 {
    padding: 3rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3rem !important; }
  .p-lg-9 {
    padding: 4rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important; }
  .p-lg-10 {
    padding: 5rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem !important; }
  .p-lg-11 {
    padding: 6rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 6rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 6rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 6rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 6rem !important; }
  .p-lg-12 {
    padding: 8rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 8rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 8rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 8rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 8rem !important; }
  .p-lg-13 {
    padding: 10rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 10rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 10rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 10rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 10rem !important; }
  .p-lg-14 {
    padding: 12rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 12rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 12rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 12rem !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 12rem !important; }
  .p-lg-15 {
    padding: 16rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 16rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 16rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 16rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 16rem !important; }
  .p-lg-16 {
    padding: 25rem !important; }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 25rem !important; }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 25rem !important; }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 25rem !important; }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 25rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -0.75rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.75rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.75rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.75rem !important; }
  .m-lg-n4 {
    margin: -1rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1rem !important; }
  .m-lg-n5 {
    margin: -1.5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.5rem !important; }
  .m-lg-n6 {
    margin: -2rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2rem !important; }
  .m-lg-n7 {
    margin: -2.5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -2.5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -2.5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -2.5rem !important; }
  .m-lg-n8 {
    margin: -3rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3rem !important; }
  .m-lg-n9 {
    margin: -4rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important; }
  .m-lg-n10 {
    margin: -5rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -5rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -5rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -5rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -5rem !important; }
  .m-lg-n11 {
    margin: -6rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -6rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -6rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -6rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -6rem !important; }
  .m-lg-n12 {
    margin: -8rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -8rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -8rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -8rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -8rem !important; }
  .m-lg-n13 {
    margin: -10rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -10rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -10rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -10rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -10rem !important; }
  .m-lg-n14 {
    margin: -12rem !important; }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -12rem !important; }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -12rem !important; }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -12rem !important; }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -12rem !important; }
  .m-lg-n15 {
    margin: -16rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -16rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -16rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -16rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -16rem !important; }
  .m-lg-n16 {
    margin: -25rem !important; }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -25rem !important; }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -25rem !important; }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -25rem !important; }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 0.75rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important; }
  .m-xl-4 {
    margin: 1rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important; }
  .m-xl-5 {
    margin: 1.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.5rem !important; }
  .m-xl-6 {
    margin: 2rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2rem !important; }
  .m-xl-7 {
    margin: 2.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 2.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 2.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 2.5rem !important; }
  .m-xl-8 {
    margin: 3rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3rem !important; }
  .m-xl-9 {
    margin: 4rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important; }
  .m-xl-10 {
    margin: 5rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem !important; }
  .m-xl-11 {
    margin: 6rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 6rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 6rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 6rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 6rem !important; }
  .m-xl-12 {
    margin: 8rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 8rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 8rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 8rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 8rem !important; }
  .m-xl-13 {
    margin: 10rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 10rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 10rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 10rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 10rem !important; }
  .m-xl-14 {
    margin: 12rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 12rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 12rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 12rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 12rem !important; }
  .m-xl-15 {
    margin: 16rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 16rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 16rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 16rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 16rem !important; }
  .m-xl-16 {
    margin: 25rem !important; }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 25rem !important; }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 25rem !important; }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 25rem !important; }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 25rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 0.75rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important; }
  .p-xl-4 {
    padding: 1rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important; }
  .p-xl-5 {
    padding: 1.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.5rem !important; }
  .p-xl-6 {
    padding: 2rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2rem !important; }
  .p-xl-7 {
    padding: 2.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 2.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 2.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 2.5rem !important; }
  .p-xl-8 {
    padding: 3rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3rem !important; }
  .p-xl-9 {
    padding: 4rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important; }
  .p-xl-10 {
    padding: 5rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem !important; }
  .p-xl-11 {
    padding: 6rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 6rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 6rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 6rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 6rem !important; }
  .p-xl-12 {
    padding: 8rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 8rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 8rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 8rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 8rem !important; }
  .p-xl-13 {
    padding: 10rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 10rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 10rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 10rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 10rem !important; }
  .p-xl-14 {
    padding: 12rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 12rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 12rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 12rem !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 12rem !important; }
  .p-xl-15 {
    padding: 16rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 16rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 16rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 16rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 16rem !important; }
  .p-xl-16 {
    padding: 25rem !important; }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 25rem !important; }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 25rem !important; }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 25rem !important; }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 25rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -0.75rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.75rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.75rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.75rem !important; }
  .m-xl-n4 {
    margin: -1rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1rem !important; }
  .m-xl-n5 {
    margin: -1.5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.5rem !important; }
  .m-xl-n6 {
    margin: -2rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2rem !important; }
  .m-xl-n7 {
    margin: -2.5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -2.5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -2.5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -2.5rem !important; }
  .m-xl-n8 {
    margin: -3rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3rem !important; }
  .m-xl-n9 {
    margin: -4rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important; }
  .m-xl-n10 {
    margin: -5rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -5rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -5rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -5rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -5rem !important; }
  .m-xl-n11 {
    margin: -6rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -6rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -6rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -6rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -6rem !important; }
  .m-xl-n12 {
    margin: -8rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -8rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -8rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -8rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -8rem !important; }
  .m-xl-n13 {
    margin: -10rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -10rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -10rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -10rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -10rem !important; }
  .m-xl-n14 {
    margin: -12rem !important; }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -12rem !important; }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -12rem !important; }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -12rem !important; }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -12rem !important; }
  .m-xl-n15 {
    margin: -16rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -16rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -16rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -16rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -16rem !important; }
  .m-xl-n16 {
    margin: -25rem !important; }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -25rem !important; }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -25rem !important; }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -25rem !important; }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #335EEA !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #133bbd !important; }

.text-secondary {
  color: #506690 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #35435f !important; }

.text-success {
  color: #42BA96 !important; }

a.text-success:hover, a.text-success:focus {
  color: #2e8268 !important; }

.text-info {
  color: #7C69EF !important; }

a.text-info:hover, a.text-info:focus {
  color: #4024e8 !important; }

.text-warning {
  color: #FAD776 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f7c12c !important; }

.text-danger {
  color: #DF4759 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b92032 !important; }

.text-light {
  color: #F9FBFD !important; }

a.text-light:hover, a.text-light:focus {
  color: #c0d5ea !important; }

.text-dark {
  color: #1B2A4E !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #070b15 !important; }

.text-primary-desat {
  color: #6C8AEC !important; }

a.text-primary-desat:hover, a.text-primary-desat:focus {
  color: #2854e3 !important; }

.text-black {
  color: #161C2D !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-white {
  color: #FFFFFF !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-body {
  color: #161C2D !important; }

.text-muted {
  color: #869AB8 !important; }

.text-black-50 {
  color: rgba(22, 28, 45, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #ABBCD5;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #161C2D; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #D9E2EF !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #F1F4F8; }
  .table .thead-dark th {
    color: inherit;
    border-color: #F1F4F8; } }

.alert-primary {
  color: #FFFFFF;
  background-color: #335eea;
  border-color: #335eea; }
  .alert-primary hr {
    border-top-color: #1c4ce8; }
  .alert-primary .alert-link {
    color: #e6e6e6; }
  .alert-primary .close,
  .alert-primary .alert-link {
    color: #FFFFFF; }

.alert-secondary {
  color: #FFFFFF;
  background-color: #506690;
  border-color: #506690; }
  .alert-secondary hr {
    border-top-color: #475a80; }
  .alert-secondary .alert-link {
    color: #e6e6e6; }
  .alert-secondary .close,
  .alert-secondary .alert-link {
    color: #FFFFFF; }

.alert-success {
  color: #FFFFFF;
  background-color: #42ba96;
  border-color: #42ba96; }
  .alert-success hr {
    border-top-color: #3ba787; }
  .alert-success .alert-link {
    color: #e6e6e6; }
  .alert-success .close,
  .alert-success .alert-link {
    color: #FFFFFF; }

.alert-info {
  color: #FFFFFF;
  background-color: #7c69ef;
  border-color: #7c69ef; }
  .alert-info hr {
    border-top-color: #6852ed; }
  .alert-info .alert-link {
    color: #e6e6e6; }
  .alert-info .close,
  .alert-info .alert-link {
    color: #FFFFFF; }

.alert-warning {
  color: #1B2A4E;
  background-color: #fad776;
  border-color: #fad776; }
  .alert-warning hr {
    border-top-color: #f9d05d; }
  .alert-warning .alert-link {
    color: #0e1628; }
  .alert-warning .close,
  .alert-warning .alert-link {
    color: #1B2A4E; }

.alert-danger {
  color: #FFFFFF;
  background-color: #df4759;
  border-color: #df4759; }
  .alert-danger hr {
    border-top-color: #db3145; }
  .alert-danger .alert-link {
    color: #e6e6e6; }
  .alert-danger .close,
  .alert-danger .alert-link {
    color: #FFFFFF; }

.alert-light {
  color: #1B2A4E;
  background-color: #f9fbfd;
  border-color: #f9fbfd; }
  .alert-light hr {
    border-top-color: #e6eef7; }
  .alert-light .alert-link {
    color: #0e1628; }
  .alert-light .close,
  .alert-light .alert-link {
    color: #1B2A4E; }

.alert-dark {
  color: #FFFFFF;
  background-color: #1b2a4e;
  border-color: #1b2a4e; }
  .alert-dark hr {
    border-top-color: #14203b; }
  .alert-dark .alert-link {
    color: #e6e6e6; }
  .alert-dark .close,
  .alert-dark .alert-link {
    color: #FFFFFF; }

.alert-primary-desat {
  color: #FFFFFF;
  background-color: #6c8aec;
  border-color: #6c8aec; }
  .alert-primary-desat hr {
    border-top-color: #5578e9; }
  .alert-primary-desat .alert-link {
    color: #e6e6e6; }
  .alert-primary-desat .close,
  .alert-primary-desat .alert-link {
    color: #FFFFFF; }

.alert-black {
  color: #FFFFFF;
  background-color: #161c2d;
  border-color: #161c2d; }
  .alert-black hr {
    border-top-color: #0e111c; }
  .alert-black .alert-link {
    color: #e6e6e6; }
  .alert-black .close,
  .alert-black .alert-link {
    color: #FFFFFF; }

.alert-white {
  color: #1B2A4E;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #0e1628; }
  .alert-white .close,
  .alert-white .alert-link {
    color: #1B2A4E; }

.badge-primary-soft {
  background-color: rgba(51, 94, 234, 0.1);
  color: #335EEA; }
  a.badge-primary-soft:hover, a.badge-primary-soft:focus {
    background-color: rgba(51, 94, 234, 0.2);
    color: #335EEA; }

.badge-secondary-soft {
  background-color: rgba(80, 102, 144, 0.1);
  color: #506690; }
  a.badge-secondary-soft:hover, a.badge-secondary-soft:focus {
    background-color: rgba(80, 102, 144, 0.2);
    color: #506690; }

.badge-success-soft {
  background-color: rgba(66, 186, 150, 0.1);
  color: #42BA96; }
  a.badge-success-soft:hover, a.badge-success-soft:focus {
    background-color: rgba(66, 186, 150, 0.2);
    color: #42BA96; }

.badge-info-soft {
  background-color: rgba(124, 105, 239, 0.1);
  color: #7C69EF; }
  a.badge-info-soft:hover, a.badge-info-soft:focus {
    background-color: rgba(124, 105, 239, 0.2);
    color: #7C69EF; }

.badge-warning-soft {
  background-color: rgba(250, 215, 118, 0.1);
  color: #FAD776; }
  a.badge-warning-soft:hover, a.badge-warning-soft:focus {
    background-color: rgba(250, 215, 118, 0.2);
    color: #FAD776; }

.badge-danger-soft {
  background-color: rgba(223, 71, 89, 0.1);
  color: #DF4759; }
  a.badge-danger-soft:hover, a.badge-danger-soft:focus {
    background-color: rgba(223, 71, 89, 0.2);
    color: #DF4759; }

.badge-light-soft {
  background-color: rgba(249, 251, 253, 0.1);
  color: #F9FBFD; }
  a.badge-light-soft:hover, a.badge-light-soft:focus {
    background-color: rgba(249, 251, 253, 0.2);
    color: #F9FBFD; }

.badge-dark-soft {
  background-color: rgba(27, 42, 78, 0.1);
  color: #1B2A4E; }
  a.badge-dark-soft:hover, a.badge-dark-soft:focus {
    background-color: rgba(27, 42, 78, 0.2);
    color: #1B2A4E; }

.badge-primary-desat-soft {
  background-color: rgba(108, 138, 236, 0.1);
  color: #6C8AEC; }
  a.badge-primary-desat-soft:hover, a.badge-primary-desat-soft:focus {
    background-color: rgba(108, 138, 236, 0.2);
    color: #6C8AEC; }

.badge-black-soft {
  background-color: rgba(22, 28, 45, 0.1);
  color: #161C2D; }
  a.badge-black-soft:hover, a.badge-black-soft:focus {
    background-color: rgba(22, 28, 45, 0.2);
    color: #161C2D; }

.badge-white-soft {
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFFFFF; }
  a.badge-white-soft:hover, a.badge-white-soft:focus {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF; }

.badge-gray-700-soft {
  background-color: rgba(80, 102, 144, 0.1);
  color: #506690; }
  a.badge-gray-700-soft:hover, a.badge-gray-700-soft:focus {
    background-color: rgba(80, 102, 144, 0.2);
    color: #506690; }

.badge-gray-600 {
  background-color: #869AB8;
  color: #FFFFFF; }

.badge-lg {
  padding: 0.7em 1.15em; }

.badge-rounded-circle {
  height: calc(1em + 0.5rem);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 10rem; }
  .badge-rounded-circle > * {
    display: block;
    width: 1em; }
  .badge-rounded-circle.badge-lg {
    height: calc(1em + 1.4em);
    padding-left: 0.7em;
    padding-right: 0.7em; }

.badge-float {
  position: absolute;
  z-index: 1000; }

.badge-float-outside {
  top: -.5rem;
  right: -.5rem; }

.badge-float-inside {
  top: 1rem;
  right: 1rem; }

.badge-white-soft.active {
  background-color: #FFFFFF;
  color: #335EEA; }
  .badge-white-soft.active:hover, .badge-white-soft.active:focus {
    background-color: #f2f2f2;
    color: #335EEA; }

.badge-secondary-soft.active {
  background-color: #335EEA;
  color: #FFFFFF; }
  .badge-secondary-soft.active:hover, .badge-secondary-soft.active:focus {
    background-color: #1c4ce8;
    color: #FFFFFF; }

.breadcrumb-item {
  font-size: 0.9375rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "\E930";
    font-family: "Feather"; }

.breadcrumb-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto; }
  .breadcrumb-scroll .breadcrumb-item {
    white-space: nowrap; }
  .breadcrumb-scroll::-webkit-scrollbar {
    display: none; }

.btn > img {
  max-height: 1em;
  width: auto; }

.btn:not([class*="btn-outline"]) {
  border-color: transparent !important; }

.btn-xs {
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem; }

.btn-white {
  background-color: #FFFFFF;
  color: #335EEA; }
  .btn-white:hover, .btn-white:focus {
    background-color: #FFFFFF;
    color: #1c4ce8; }
  .btn-white.active {
    background-color: #f2f2f2;
    color: #1c4ce8; }

.btn-group .btn-white {
  border-color: #D9E2EF !important;
  color: #869AB8; }
  .btn-group .btn-white.active {
    background-color: #f2f2f2;
    color: #768daf;
    box-shadow: none; }

.btn-gray-300-20 {
  background-color: rgba(217, 226, 239, 0.2);
  color: #FFFFFF; }
  .btn-gray-300-20:hover, .btn-gray-300-20:focus {
    background-color: rgba(217, 226, 239, 0.25);
    color: #FFFFFF; }
  .btn-gray-300-20.active {
    background-color: rgba(217, 226, 239, 0.25);
    color: #FFFFFF; }

.btn-gray-400 {
  background-color: #C6D3E6;
  color: #FFFFFF; }
  .btn-gray-400:hover, .btn-gray-400:focus {
    background-color: #b4c5de;
    color: #f2f2f2; }
  .btn-gray-400.active {
    background-color: #b4c5de;
    color: #f2f2f2; }

.btn-gray-400-10 {
  background-color: rgba(198, 211, 230, 0.1);
  color: #FFFFFF; }
  .btn-gray-400-10:hover, .btn-gray-400-10:focus {
    background-color: rgba(198, 211, 230, 0.15);
    color: #FFFFFF; }
  .btn-gray-400-10.active {
    background-color: rgba(198, 211, 230, 0.15);
    color: #FFFFFF; }

.btn-outline-white {
  border-color: #D9E2EF;
  color: #161C2D; }
  .btn-outline-white:hover, .btn-outline-white:focus {
    background-color: #f2f2f2;
    border-color: #C6D3E6;
    color: #161C2D; }
  .btn-outline-white.active {
    background-color: #f2f2f2;
    border-color: #C6D3E6;
    color: #161C2D; }

.btn-outline-gray-300 {
  border-color: #D9E2EF;
  color: #335EEA; }
  .btn-outline-gray-300:hover, .btn-outline-gray-300:focus {
    background-color: #335EEA;
    border-color: #335EEA;
    color: #FFFFFF; }
  .btn-outline-gray-300.active {
    background-color: #335EEA;
    border-color: #335EEA;
    color: #FFFFFF; }

.btn-primary-soft {
  background-color: rgba(51, 94, 234, 0.1);
  color: #335EEA; }
  .btn-primary-soft:hover, .btn-primary-soft:focus {
    background-color: rgba(51, 94, 234, 0.15);
    color: #335EEA; }
  .btn-primary-soft.active {
    background-color: rgba(51, 94, 234, 0.15);
    color: #335EEA; }

.btn-secondary-soft {
  background-color: rgba(80, 102, 144, 0.1);
  color: #506690; }
  .btn-secondary-soft:hover, .btn-secondary-soft:focus {
    background-color: rgba(80, 102, 144, 0.15);
    color: #506690; }
  .btn-secondary-soft.active {
    background-color: rgba(80, 102, 144, 0.15);
    color: #506690; }

.btn-success-soft {
  background-color: rgba(66, 186, 150, 0.1);
  color: #42BA96; }
  .btn-success-soft:hover, .btn-success-soft:focus {
    background-color: rgba(66, 186, 150, 0.15);
    color: #42BA96; }
  .btn-success-soft.active {
    background-color: rgba(66, 186, 150, 0.15);
    color: #42BA96; }

.btn-info-soft {
  background-color: rgba(124, 105, 239, 0.1);
  color: #7C69EF; }
  .btn-info-soft:hover, .btn-info-soft:focus {
    background-color: rgba(124, 105, 239, 0.15);
    color: #7C69EF; }
  .btn-info-soft.active {
    background-color: rgba(124, 105, 239, 0.15);
    color: #7C69EF; }

.btn-warning-soft {
  background-color: rgba(250, 215, 118, 0.1);
  color: #FAD776; }
  .btn-warning-soft:hover, .btn-warning-soft:focus {
    background-color: rgba(250, 215, 118, 0.15);
    color: #FAD776; }
  .btn-warning-soft.active {
    background-color: rgba(250, 215, 118, 0.15);
    color: #FAD776; }

.btn-danger-soft {
  background-color: rgba(223, 71, 89, 0.1);
  color: #DF4759; }
  .btn-danger-soft:hover, .btn-danger-soft:focus {
    background-color: rgba(223, 71, 89, 0.15);
    color: #DF4759; }
  .btn-danger-soft.active {
    background-color: rgba(223, 71, 89, 0.15);
    color: #DF4759; }

.btn-light-soft {
  background-color: rgba(249, 251, 253, 0.1);
  color: #F9FBFD; }
  .btn-light-soft:hover, .btn-light-soft:focus {
    background-color: rgba(249, 251, 253, 0.15);
    color: #F9FBFD; }
  .btn-light-soft.active {
    background-color: rgba(249, 251, 253, 0.15);
    color: #F9FBFD; }

.btn-dark-soft {
  background-color: rgba(27, 42, 78, 0.1);
  color: #1B2A4E; }
  .btn-dark-soft:hover, .btn-dark-soft:focus {
    background-color: rgba(27, 42, 78, 0.15);
    color: #1B2A4E; }
  .btn-dark-soft.active {
    background-color: rgba(27, 42, 78, 0.15);
    color: #1B2A4E; }

.btn-primary-desat-soft {
  background-color: rgba(108, 138, 236, 0.1);
  color: #6C8AEC; }
  .btn-primary-desat-soft:hover, .btn-primary-desat-soft:focus {
    background-color: rgba(108, 138, 236, 0.15);
    color: #6C8AEC; }
  .btn-primary-desat-soft.active {
    background-color: rgba(108, 138, 236, 0.15);
    color: #6C8AEC; }

.btn-black-soft {
  background-color: rgba(22, 28, 45, 0.1);
  color: #161C2D; }
  .btn-black-soft:hover, .btn-black-soft:focus {
    background-color: rgba(22, 28, 45, 0.15);
    color: #161C2D; }
  .btn-black-soft.active {
    background-color: rgba(22, 28, 45, 0.15);
    color: #161C2D; }

.btn-white-soft {
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFFFFF; }
  .btn-white-soft:hover, .btn-white-soft:focus {
    background-color: rgba(255, 255, 255, 0.15);
    color: #FFFFFF; }
  .btn-white-soft.active {
    background-color: rgba(255, 255, 255, 0.15);
    color: #FFFFFF; }

.btn-pill {
  border-radius: 50rem; }

.btn-rounded-circle {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  border-radius: 50rem; }

.btn-rounded-circle > * {
  display: block;
  line-height: 1;
  width: 1em; }

.btn-rounded-circle.btn-lg, .btn-group-lg > .btn-rounded-circle.btn {
  padding-left: 1.122rem;
  padding-right: 1.122rem; }

.btn-rounded-circle.btn-sm, .btn-group-sm > .btn-rounded-circle.btn {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem; }

.btn-rounded-circle.btn-xs {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.card {
  position: relative;
  width: 100%; }

.card-header {
  background-color: transparent;
  border-bottom: 1px solid #F1F4F8; }

.card-body {
  display: block;
  flex-grow: 0; }

.card-footer {
  background-color: unset; }

.card-body, .card-footer, .card-meta, .card-img, .card-img-top, .card-img-left, .card-img-right {
  position: relative;
  min-height: 1px; }

a.card, a.card-body, a.card-footer, a.card-meta {
  color: inherit; }
  a.card:hover, a.card-body:hover, a.card-footer:hover, a.card-meta:hover {
    text-decoration: none; }

.card-flush {
  background-color: unset; }

.card-flush > *:not(.card-btn) {
  padding-left: 0;
  padding-right: 0; }

.card-border {
  position: relative; }

.card-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top-width: 2px;
  border-bottom-width: calc(0.375rem - 2px);
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: inherit;
  border-bottom-color: transparent;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem; }

.card-border-lg::after {
  border-top-width: 3px;
  border-bottom-width: calc(0.375rem - 3px); }

.card-border-xl::after {
  border-top-width: 4px;
  border-bottom-width: calc(0.375rem - 3px); }

@media (min-width: 768px) {
  .card-row .card-body {
    padding: 4rem 2.5rem; } }

@media (min-width: 768px) {
  .card-row .card-meta {
    padding-left: 2.5rem;
    padding-right: 2.5rem; } }

.card-img-right {
  border-radius: 0.375rem 0.375rem 0 0; }
  @media (min-width: 768px) {
    .card-img-right {
      border-radius: 0 0.375rem 0.375rem 0; } }

.card-img-left {
  border-radius: 0.375rem 0.375rem 0 0; }
  @media (min-width: 768px) {
    .card-img-left {
      border-radius: 0.375rem 0 0 0.375rem; } }

@media (min-width: 768px) {
  .card-img-slider {
    height: 100%;
    width: 100%; }
    .card-img-slider * {
      height: inherit !important;
      width: inherit !important; } }

.card-img-overlay {
  display: flex;
  flex-direction: column;
  padding: 2rem; }

.card-img-overlay .card-body {
  margin: -2rem;
  margin-top: auto;
  border-bottom-right-radius: calc(0.375rem - 0px);
  border-bottom-left-radius: calc(0.375rem - 0px); }

.card-img-overlay-hover {
  overflow: hidden; }

.card-img-overlay-hover .card-body {
  transform: translateY(110%);
  transition: all .3s ease; }

.card-img-overlay-hover:hover .card-body {
  transform: translateY(0); }

@media (min-width: 576px) and (max-width: 767.98px) {
  .card-group {
    flex-direction: column !important; }
    .card-group > .card {
      flex: auto; } }

.card-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 2rem 1.5rem; }

.card-meta-divider {
  width: 100%;
  margin: 0 0 1.5rem; }

.card-btn:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.card-zoom {
  overflow: hidden !important;
  border-radius: inherit; }

.card-zoom > [class*="card-img"] {
  transition: all .3s ease;
  transform-origin: center center; }

.card:hover > .card-zoom > [class*="card-img"] {
  transform: scale(1.1); }

.card-list .list-link {
  position: relative; }

.card-list .list-link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2rem;
  border-right: 2px solid #335EEA;
  display: none; }

.card-list .list-link:hover {
  color: #161C2D !important; }

.card-list .active .list-link {
  color: #161C2D !important; }

.card-list .active .list-link::before {
  display: block; }

@media (max-width: 767.98px) {
  .card-bleed {
    width: auto;
    min-width: 100%;
    margin-right: -20px;
    margin-left: -20px;
    border-radius: 0; }
  .card-bleed .card-footer,
  .card-bleed .card-header,
  .card-bleed .card-body {
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 0; }
  .card-bleed .card-list .list-link::before {
    left: -20px; } }

.close:hover, .close:focus {
  outline: none; }

.custom-switch {
  min-height: 1.375rem; }
  .custom-switch .custom-control-label::before {
    top: 0.1625rem;
    height: 1.375rem;
    border-radius: 0.6875rem; }
  .custom-switch .custom-control-label::after {
    top: 0.35rem;
    left: -2.8125rem;
    background-color: #FFFFFF; }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFFFFF;
    transform: translateX(1.125rem); }

.custom-switch-dark .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2); }

.custom-switch-dark .custom-control-label::after {
  background-color: #FFFFFF; }

.custom-switch-dark .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFFFFF; }

.custom-switch-dark .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #335EEA; }

.custom-select-sm {
  background: #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1rem center/auto 50%; }

.custom-select-xs {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 1.875rem 0.25rem 0.625rem;
  background: #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23C6D3E6' stroke-width='2' stroke-linecap='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 0.625rem center/auto 50%;
  line-height: 1.6;
  font-size: 0.9375rem; }

.dropdown-menu {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.dropdown-item {
  width: auto;
  min-width: 100%;
  margin-left: -1.75rem;
  margin-right: -1.75rem; }
  .dropdown-item:hover, .dropdown-item:focus {
    outline: none; }

.dropdown-item + .dropdown-item {
  margin-top: 0.45rem; }

.dropdown-link {
  color: inherit; }
  .dropdown-link:hover {
    text-decoration: inherit; }
  .dropdown-link:hover, .dropdown-link:focus {
    outline: none; }

.dropdown-header {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.55;
  text-transform: uppercase;
  letter-spacing: .08em; }

.dropdown-toggle::after {
  font-family: "Feather";
  vertical-align: middle;
  border: none !important;
  content: "\E92E"; }

.dropright > .dropdown-toggle::after {
  content: "\E930"; }

.dropdown-menu-xs {
  min-width: 0;
  padding: 1rem 1.25rem; }

.dropdown-menu-md {
  min-width: 22rem; }

.dropdown-menu-lg {
  min-width: 35rem; }

.dropdown-menu-xl {
  min-width: 42rem; }

.dropdown-menu-lg,
.dropdown-menu-xl {
  padding: 2.375rem 2.5rem; }

.dropdown-menu-lg .dropdown-item,
.dropdown-menu-xl .dropdown-header {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.dropright > .dropdown-menu {
  top: -1.625rem; }

.form-control-xs {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem; }

.form-control-flush {
  padding-left: 0;
  padding-right: 0;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }

.custom-file-label::after {
  font-weight: 600; }

.form-label-group {
  position: relative;
  margin-bottom: 1rem; }

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(1.6em + 1.625rem + 2px);
  padding: 0.8125rem 1.25rem;
  margin-bottom: 0;
  line-height: 1.6;
  font-size: 1.0625rem;
  color: #869AB8;
  pointer-events: none;
  cursor: text;
  transition: all 0.2s ease-in-out; }

.form-label-group > .form-control-flush ~ label {
  padding-left: 0;
  padding-right: 0; }

.form-label-group > .form-control::-webkit-input-placeholder {
  color: transparent; }

.form-label-group > .form-control::-ms-input-placeholder {
  color: transparent; }

.form-label-group > .form-control::placeholder {
  color: transparent; }

.form-label-group > .form-control:not(:placeholder-shown) {
  padding-top: 1.21875rem;
  padding-bottom: 0.40625rem; }

.form-label-group > .form-control:not(:placeholder-shown) ~ label {
  padding-top: 0.00937rem;
  padding-bottom: 0.00937rem;
  font-size: 0.75rem; }

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none; }
  .form-label-group .form-control::-webkit-input-placeholder {
    color: #869AB8; }
  .form-label-group .form-control::-ms-input-placeholder {
    color: #869AB8; }
  .form-label-group .form-control::placeholder {
    color: #869AB8; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none; }
  .form-label-group .form-control::-webkit-input-placeholder {
    color: #869AB8; }
  .form-label-group .form-control::-ms-input-placeholder {
    color: #869AB8; }
  .form-label-group .form-control::placeholder {
    color: #869AB8; } }

[class^="container"] {
  position: relative;
  z-index: 1; }

.img-cover {
  width: 100vw;
  height: auto;
  margin-left: calc(50% - 50vw); }

.img-fluid > img,
.img-fluid > svg {
  max-width: inherit;
  height: inherit; }

.figure {
  display: block; }

.figure-img {
  margin-bottom: 1rem; }

.input-group-xs .form-control {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem; }

.input-group-xs .input-group-text {
  padding: 0.25rem 0.625rem;
  font-size: 0.9375rem; }

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0; }
  .list-group-flush .list-group-item:first-child {
    padding-top: 0 !important;
    border-top: 0; }
  .list-group-flush .list-group-item:last-child {
    padding-bottom: 0 !important;
    border-bottom: 0; }

.modal-open .navbar.fixed-top {
  padding-right: inherit; }

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1000; }

.navbar {
  transition: all 0.2s ease-in-out;
  transition-property: background-color, color;
  z-index: 1030; }

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-item .nav-link {
    color: #354178; }
    .navbar-dark .navbar-nav .nav-item .nav-link:hover, .navbar-dark .navbar-nav .nav-item .nav-link:focus {
      color: #99a4d2; }
  .navbar-dark .navbar-nav .nav-item.active .nav-link,
  .navbar-dark .navbar-nav .nav-item .nav-link.active {
    color: #99a4d2; }
  .navbar-dark .navbar-collapse .navbar-toggler {
    color: #354178; } }

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.navbar-brand {
  font-weight: 600; }

.navbar-brand-img {
  max-height: 2.4rem;
  width: auto; }

.navbar-dark .navbar-brand {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1); }

@media (max-width: 991.98px) {
  .navbar-btn {
    width: 100%;
    padding: 0.8125rem 1.25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 1.0625rem; } }

.navbar-nav .nav-link {
  font-weight: 600; }
  .navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
    outline: none; }

.navbar-dark.fixed-top > .container {
  position: relative; }
  .navbar-dark.fixed-top > .container::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -1.25rem;
    left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2); }

@media (max-width: 991.98px) {
  .navbar-collapse {
    position: fixed;
    top: 1rem;
    left: 1rem;
    height: auto;
    max-height: calc(100% - 2rem) !important;
    width: calc(100% - 2rem);
    background-color: #FFFFFF;
    border-radius: 0.375rem;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    overflow-x: hidden;
    overflow-y: scroll; }
  .navbar-collapse.show,
  .navbar-collapse.collapsing {
    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform;
    transform-origin: top right; }
  .navbar-collapse.show {
    opacity: 1;
    transform: scale(1); }
  .navbar-collapse.collapsing {
    opacity: 0;
    transform: scale(0.9); } }

.navbar .navbar-toggler {
  margin-left: auto; }

.navbar-collapse .navbar-toggler {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1; }
  @media (min-width: 992px) {
    .navbar-collapse .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .nav-item {
    padding: 1.5rem; }
    .navbar-collapse .navbar-nav .nav-item + .nav-item {
      border-top: 1px solid #F1F4F8; } }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0; } }

.navbar-nav .dropdown-menu {
  box-shadow: none; }
  @media (min-width: 992px) {
    .navbar-nav .dropdown-menu {
      box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15); } }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown-menu {
    min-width: 0;
    padding: 0; } }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown > .dropdown-menu {
    display: block !important; } }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropright > .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem; } }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown-item,
  .navbar-collapse .navbar-nav .dropdown-header {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; } }

.navbar-nav .dropdown > .dropdown-toggle::after {
  display: none; }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown > .dropdown-toggle {
    margin-bottom: 1.5rem;
    pointer-events: none; } }

.navbar-nav .dropright > .dropdown-toggle {
  display: flex; }
  .navbar-nav .dropright > .dropdown-toggle::after {
    margin-left: auto; }

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropright > .dropdown-toggle::after {
    content: "\E92E"; }
  .navbar-collapse .navbar-nav .dropright > .dropdown-toggle[aria-expanded="true"]::after {
    transform-origin: center center;
    transform: rotate(180deg); } }

.navbar-nav .dropdown-img-left {
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.625rem 1.75rem;
  background: no-repeat center center / cover;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }
  @media (min-width: 992px) {
    .navbar-nav .dropdown-img-left {
      display: flex; } }
  .navbar-nav .dropdown-img-left::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(51, 94, 234, 0.8);
    border-radius: inherit; }
  .navbar-nav .dropdown-img-left * {
    position: relative; }

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu-lg .dropdown-img-left,
  .navbar-nav .dropdown-menu-xl .dropdown-img-left {
    padding: 2.375rem 2.5rem; } }

@media (min-width: 992px) {
  .navbar-nav .dropdown-body {
    padding: 1.625rem 1.75rem; } }

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu-lg .dropdown-body,
  .navbar-nav .dropdown-menu-xl .dropdown-body {
    padding: 2.375rem 2.5rem; } }

.navbar-nav .dropdown-menu .list-group-item {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: inherit; }
  .navbar-nav .dropdown-menu .list-group-item:hover {
    text-decoration: none; }

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transition-property: opacity, transform; }
  .navbar-nav .dropright > .dropdown-menu {
    transform: translateY(10px); }
  .navbar-nav .dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 10px); }
  .navbar-nav .dropdown-menu.showing {
    display: block; }
  .navbar-nav .dropdown-menu.show {
    display: block;
    opacity: 1; }
  .navbar-nav .dropright > .dropdown-menu.show {
    transform: translateY(0); }
  .navbar-nav .dropdown > .dropdown-menu.show {
    transform: translate(-50%, 0); } }

.pagination-sm .page-link {
  line-height: 1.6;
  font-size: 1.0625rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.pagination-lg .page-link {
  line-height: 1.6;
  font-size: 1.0625rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.popover-header {
  padding-bottom: 0;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #335EEA; }

.popover-header:not(:empty) ~ .popover-body {
  padding-top: 0; }

html, body {
  overflow-x: hidden; }

.table thead > tr > th {
  border-top-width: 0;
  border-bottom-width: 1px; }

.table-align-middle thead > tr > th,
.table-align-middle tbody > tr > td {
  vertical-align: middle; }

@font-face {
  font-family: "HKGroteskPro";
  font-weight: 400;
  src: url("/fonts/HKGroteskPro/HKGroteskPro-Regular.woff2") format("woff2"), url("/fonts/HKGroteskPro/HKGroteskPro-Regular.woff") format("woff"); }

@font-face {
  font-family: "HKGroteskPro";
  font-weight: 600;
  src: url("/fonts/HKGroteskPro/HKGroteskPro-Medium.woff2") format("woff2"), url("/fonts/HKGroteskPro/HKGroteskPro-Medium.woff") format("woff"); }

h1, h2,
.h1, .h2 {
  margin-bottom: 1rem; }

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  letter-spacing: -0.01em; }

h1, .h1 {
  line-height: 1.3; }
  @media (max-width: 991.98px) {
    h1, .h1 {
      font-size: 1.875rem; } }

h2, .h2 {
  line-height: 1.35; }
  @media (max-width: 991.98px) {
    h2, .h2 {
      font-size: 1.5rem; } }

h3, .h3 {
  line-height: 1.4; }
  @media (max-width: 991.98px) {
    h3, .h3 {
      font-size: 1.125rem; } }

h4, .h4 {
  line-height: 1.45; }
  @media (max-width: 991.98px) {
    h4, .h4 {
      font-size: 1.0625rem; } }

h5, .h5 {
  line-height: 1.5; }

h6, .h6 {
  line-height: 1.55; }
  h6.text-uppercase, .h6.text-uppercase {
    letter-spacing: .08em; }

.display-1, .display-2, .display-3, .display-4 {
  letter-spacing: -0.02em; }

.display-1 {
  line-height: 1.2; }
  @media (max-width: 991.98px) {
    .display-1 {
      font-size: 3.125rem; } }

.display-2 {
  line-height: 1.2; }
  @media (max-width: 991.98px) {
    .display-2 {
      font-size: 2.5rem; } }

.display-3 {
  line-height: 1.2; }
  @media (max-width: 991.98px) {
    .display-3 {
      font-size: 2.25rem; } }

.display-4 {
  line-height: 1.2; }
  @media (max-width: 991.98px) {
    .display-4 {
      font-size: 2rem; } }

@media (max-width: 991.98px) {
  .lead {
    font-size: 1.1875rem; } }

.blockquote-img {
  max-height: 2.5rem;
  max-width: 100%;
  width: auto; }

.blockquote-footer::before {
  display: none; }

.list-social-icon {
  max-width: 1.25rem;
  height: auto; }

.hr-sm {
  width: 60px;
  border-top-width: 2px; }

.hr-md {
  width: 100px;
  border-top-width: 2px; }

code {
  line-height: 1.35; }

.bg-cover {
  background: no-repeat center center / cover; }

@media (max-width: 991.98px) {
  .bg-between {
    background-image: none !important; } }

@media (min-width: 768px) {
  .bg-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 70%; } }

.bg-gradient-light {
  background-image: linear-gradient(to bottom, #F9FBFD 0%, #FFFFFF 100%); }

.bg-gray-200 {
  background-color: #F1F4F8 !important; }

.bg-gray-300 {
  background-color: #D9E2EF !important; }

.bg-gray-800 {
  background-color: #384C74 !important; }

.bg-gray-900 {
  background-color: #1B2A4E !important; }

.bg-black {
  background-color: #161C2D !important; }

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #F1F4F8 !important;
    color: rose; }
  .border-top-sm {
    border-top: 1px solid #F1F4F8 !important; }
  .border-right-sm {
    border-right: 1px solid #F1F4F8 !important; }
  .border-bottom-sm {
    border-bottom: 1px solid #F1F4F8 !important; }
  .border-left-sm {
    border-left: 1px solid #F1F4F8 !important; }
  .border-sm-0 {
    border: 0 !important; }
  .border-top-sm-0 {
    border-top: 0 !important; }
  .border-right-sm-0 {
    border-right: 0 !important; }
  .border-bottom-sm-0 {
    border-bottom: 0 !important; }
  .border-left-sm-0 {
    border-left: 0 !important; } }

@media (min-width: 768px) {
  .border-md {
    border: 1px solid #F1F4F8 !important;
    color: rose; }
  .border-top-md {
    border-top: 1px solid #F1F4F8 !important; }
  .border-right-md {
    border-right: 1px solid #F1F4F8 !important; }
  .border-bottom-md {
    border-bottom: 1px solid #F1F4F8 !important; }
  .border-left-md {
    border-left: 1px solid #F1F4F8 !important; }
  .border-md-0 {
    border: 0 !important; }
  .border-top-md-0 {
    border-top: 0 !important; }
  .border-right-md-0 {
    border-right: 0 !important; }
  .border-bottom-md-0 {
    border-bottom: 0 !important; }
  .border-left-md-0 {
    border-left: 0 !important; } }

@media (min-width: 992px) {
  .border-lg {
    border: 1px solid #F1F4F8 !important;
    color: rose; }
  .border-top-lg {
    border-top: 1px solid #F1F4F8 !important; }
  .border-right-lg {
    border-right: 1px solid #F1F4F8 !important; }
  .border-bottom-lg {
    border-bottom: 1px solid #F1F4F8 !important; }
  .border-left-lg {
    border-left: 1px solid #F1F4F8 !important; }
  .border-lg-0 {
    border: 0 !important; }
  .border-top-lg-0 {
    border-top: 0 !important; }
  .border-right-lg-0 {
    border-right: 0 !important; }
  .border-bottom-lg-0 {
    border-bottom: 0 !important; }
  .border-left-lg-0 {
    border-left: 0 !important; } }

@media (min-width: 1200px) {
  .border-xl {
    border: 1px solid #F1F4F8 !important;
    color: rose; }
  .border-top-xl {
    border-top: 1px solid #F1F4F8 !important; }
  .border-right-xl {
    border-right: 1px solid #F1F4F8 !important; }
  .border-bottom-xl {
    border-bottom: 1px solid #F1F4F8 !important; }
  .border-left-xl {
    border-left: 1px solid #F1F4F8 !important; }
  .border-xl-0 {
    border: 0 !important; }
  .border-top-xl-0 {
    border-top: 0 !important; }
  .border-right-xl-0 {
    border-right: 0 !important; }
  .border-bottom-xl-0 {
    border-bottom: 0 !important; }
  .border-left-xl-0 {
    border-left: 0 !important; } }

.border-white-10 {
  border-color: rgba(255, 255, 255, 0.1) !important; }

.border-white-20 {
  border-color: rgba(255, 255, 255, 0.2) !important; }

.border-gray-300 {
  border-color: #D9E2EF !important; }

.border-gray-800 {
  border-color: #384C74 !important; }

.border-gray-800-50 {
  border-color: rgba(56, 76, 116, 0.5) !important; }

.border-gray-900-50 {
  border-color: rgba(27, 42, 78, 0.5) !important; }

.img-skewed {
  perspective: 1500px;
  transform-style: preserve-3d; }

.img-skewed-left {
  perspective-origin: left center; }
  .img-skewed-left .img-skewed-item {
    transform: rotateY(-35deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }

.img-skewed-right {
  perspective-origin: right center; }
  .img-skewed-right .img-skewed-item {
    transform: rotateY(35deg) rotateX(15deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }

.overlay {
  position: relative; }
  .overlay::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit; }

.overlay-primary::before {
  background-color: #335EEA; }

.overlay-gradient-primary-right::before {
  background-image: linear-gradient(to bottom, transparent, #335EEA); }
  @media (min-width: 768px) {
    .overlay-gradient-primary-right::before {
      background-image: linear-gradient(to right, transparent, #335EEA 50%, #335EEA); } }

.overlay-gradient-primary-down::before {
  background-image: linear-gradient(to bottom, transparent, #335EEA); }

.overlay-gradient-primary-left::before {
  background-image: linear-gradient(to bottom, transparent, #335EEA); }
  @media (min-width: 768px) {
    .overlay-gradient-primary-left::before {
      background-image: linear-gradient(to left, transparent, #335EEA 50%, #335EEA); } }

.overlay-secondary::before {
  background-color: #506690; }

.overlay-gradient-secondary-right::before {
  background-image: linear-gradient(to bottom, transparent, #506690); }
  @media (min-width: 768px) {
    .overlay-gradient-secondary-right::before {
      background-image: linear-gradient(to right, transparent, #506690 50%, #506690); } }

.overlay-gradient-secondary-down::before {
  background-image: linear-gradient(to bottom, transparent, #506690); }

.overlay-gradient-secondary-left::before {
  background-image: linear-gradient(to bottom, transparent, #506690); }
  @media (min-width: 768px) {
    .overlay-gradient-secondary-left::before {
      background-image: linear-gradient(to left, transparent, #506690 50%, #506690); } }

.overlay-success::before {
  background-color: #42BA96; }

.overlay-gradient-success-right::before {
  background-image: linear-gradient(to bottom, transparent, #42BA96); }
  @media (min-width: 768px) {
    .overlay-gradient-success-right::before {
      background-image: linear-gradient(to right, transparent, #42BA96 50%, #42BA96); } }

.overlay-gradient-success-down::before {
  background-image: linear-gradient(to bottom, transparent, #42BA96); }

.overlay-gradient-success-left::before {
  background-image: linear-gradient(to bottom, transparent, #42BA96); }
  @media (min-width: 768px) {
    .overlay-gradient-success-left::before {
      background-image: linear-gradient(to left, transparent, #42BA96 50%, #42BA96); } }

.overlay-info::before {
  background-color: #7C69EF; }

.overlay-gradient-info-right::before {
  background-image: linear-gradient(to bottom, transparent, #7C69EF); }
  @media (min-width: 768px) {
    .overlay-gradient-info-right::before {
      background-image: linear-gradient(to right, transparent, #7C69EF 50%, #7C69EF); } }

.overlay-gradient-info-down::before {
  background-image: linear-gradient(to bottom, transparent, #7C69EF); }

.overlay-gradient-info-left::before {
  background-image: linear-gradient(to bottom, transparent, #7C69EF); }
  @media (min-width: 768px) {
    .overlay-gradient-info-left::before {
      background-image: linear-gradient(to left, transparent, #7C69EF 50%, #7C69EF); } }

.overlay-warning::before {
  background-color: #FAD776; }

.overlay-gradient-warning-right::before {
  background-image: linear-gradient(to bottom, transparent, #FAD776); }
  @media (min-width: 768px) {
    .overlay-gradient-warning-right::before {
      background-image: linear-gradient(to right, transparent, #FAD776 50%, #FAD776); } }

.overlay-gradient-warning-down::before {
  background-image: linear-gradient(to bottom, transparent, #FAD776); }

.overlay-gradient-warning-left::before {
  background-image: linear-gradient(to bottom, transparent, #FAD776); }
  @media (min-width: 768px) {
    .overlay-gradient-warning-left::before {
      background-image: linear-gradient(to left, transparent, #FAD776 50%, #FAD776); } }

.overlay-danger::before {
  background-color: #DF4759; }

.overlay-gradient-danger-right::before {
  background-image: linear-gradient(to bottom, transparent, #DF4759); }
  @media (min-width: 768px) {
    .overlay-gradient-danger-right::before {
      background-image: linear-gradient(to right, transparent, #DF4759 50%, #DF4759); } }

.overlay-gradient-danger-down::before {
  background-image: linear-gradient(to bottom, transparent, #DF4759); }

.overlay-gradient-danger-left::before {
  background-image: linear-gradient(to bottom, transparent, #DF4759); }
  @media (min-width: 768px) {
    .overlay-gradient-danger-left::before {
      background-image: linear-gradient(to left, transparent, #DF4759 50%, #DF4759); } }

.overlay-light::before {
  background-color: #F9FBFD; }

.overlay-gradient-light-right::before {
  background-image: linear-gradient(to bottom, transparent, #F9FBFD); }
  @media (min-width: 768px) {
    .overlay-gradient-light-right::before {
      background-image: linear-gradient(to right, transparent, #F9FBFD 50%, #F9FBFD); } }

.overlay-gradient-light-down::before {
  background-image: linear-gradient(to bottom, transparent, #F9FBFD); }

.overlay-gradient-light-left::before {
  background-image: linear-gradient(to bottom, transparent, #F9FBFD); }
  @media (min-width: 768px) {
    .overlay-gradient-light-left::before {
      background-image: linear-gradient(to left, transparent, #F9FBFD 50%, #F9FBFD); } }

.overlay-dark::before {
  background-color: #1B2A4E; }

.overlay-gradient-dark-right::before {
  background-image: linear-gradient(to bottom, transparent, #1B2A4E); }
  @media (min-width: 768px) {
    .overlay-gradient-dark-right::before {
      background-image: linear-gradient(to right, transparent, #1B2A4E 50%, #1B2A4E); } }

.overlay-gradient-dark-down::before {
  background-image: linear-gradient(to bottom, transparent, #1B2A4E); }

.overlay-gradient-dark-left::before {
  background-image: linear-gradient(to bottom, transparent, #1B2A4E); }
  @media (min-width: 768px) {
    .overlay-gradient-dark-left::before {
      background-image: linear-gradient(to left, transparent, #1B2A4E 50%, #1B2A4E); } }

.overlay-primary-desat::before {
  background-color: #6C8AEC; }

.overlay-gradient-primary-desat-right::before {
  background-image: linear-gradient(to bottom, transparent, #6C8AEC); }
  @media (min-width: 768px) {
    .overlay-gradient-primary-desat-right::before {
      background-image: linear-gradient(to right, transparent, #6C8AEC 50%, #6C8AEC); } }

.overlay-gradient-primary-desat-down::before {
  background-image: linear-gradient(to bottom, transparent, #6C8AEC); }

.overlay-gradient-primary-desat-left::before {
  background-image: linear-gradient(to bottom, transparent, #6C8AEC); }
  @media (min-width: 768px) {
    .overlay-gradient-primary-desat-left::before {
      background-image: linear-gradient(to left, transparent, #6C8AEC 50%, #6C8AEC); } }

.overlay-black::before {
  background-color: #161C2D; }

.overlay-gradient-black-right::before {
  background-image: linear-gradient(to bottom, transparent, #161C2D); }
  @media (min-width: 768px) {
    .overlay-gradient-black-right::before {
      background-image: linear-gradient(to right, transparent, #161C2D 50%, #161C2D); } }

.overlay-gradient-black-down::before {
  background-image: linear-gradient(to bottom, transparent, #161C2D); }

.overlay-gradient-black-left::before {
  background-image: linear-gradient(to bottom, transparent, #161C2D); }
  @media (min-width: 768px) {
    .overlay-gradient-black-left::before {
      background-image: linear-gradient(to left, transparent, #161C2D 50%, #161C2D); } }

.overlay-white::before {
  background-color: #FFFFFF; }

.overlay-gradient-white-right::before {
  background-image: linear-gradient(to bottom, transparent, #FFFFFF); }
  @media (min-width: 768px) {
    .overlay-gradient-white-right::before {
      background-image: linear-gradient(to right, transparent, #FFFFFF 50%, #FFFFFF); } }

.overlay-gradient-white-down::before {
  background-image: linear-gradient(to bottom, transparent, #FFFFFF); }

.overlay-gradient-white-left::before {
  background-image: linear-gradient(to bottom, transparent, #FFFFFF); }
  @media (min-width: 768px) {
    .overlay-gradient-white-left::before {
      background-image: linear-gradient(to left, transparent, #FFFFFF 50%, #FFFFFF); } }

.overlay-10::before {
  opacity: 0.1; }

.overlay-20::before {
  opacity: 0.2; }

.overlay-30::before {
  opacity: 0.3; }

.overlay-40::before {
  opacity: 0.4; }

.overlay-50::before {
  opacity: 0.5; }

.overlay-60::before {
  opacity: 0.6; }

.overlay-70::before {
  opacity: 0.7; }

.overlay-80::before {
  opacity: 0.8; }

.overlay-90::before {
  opacity: 0.9; }

.lift {
  transition: box-shadow .25s ease, transform .25s ease; }

.lift:hover,
.lift:focus {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
  transform: translate3d(0, -3px, 0); }

.lift-lg:hover,
.lift-lg:focus {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.05) !important;
  transform: translate3d(0, -5px, 0); }

.top-0 {
  top: 0 !important; }

.right-0 {
  right: 0 !important; }

.bottom-0 {
  bottom: 0 !important; }

.left-0 {
  left: 0 !important; }

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important; }
  .position-sm-relative {
    position: relative !important; }
  .position-sm-absolute {
    position: absolute !important; }
  .position-sm-fixed {
    position: fixed !important; }
  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; } }

@media (min-width: 768px) {
  .position-md-static {
    position: static !important; }
  .position-md-relative {
    position: relative !important; }
  .position-md-absolute {
    position: absolute !important; }
  .position-md-fixed {
    position: fixed !important; }
  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; } }

@media (min-width: 992px) {
  .position-lg-static {
    position: static !important; }
  .position-lg-relative {
    position: relative !important; }
  .position-lg-absolute {
    position: absolute !important; }
  .position-lg-fixed {
    position: fixed !important; }
  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; } }

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important; }
  .position-xl-relative {
    position: relative !important; }
  .position-xl-absolute {
    position: absolute !important; }
  .position-xl-fixed {
    position: fixed !important; }
  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; } }

.shadow-light {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05) !important; }

.shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05) !important; }

.shadow-dark {
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.15) !important; }

.shadow-dark-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15) !important; }

.shadow-lift {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1), 0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important; }

.mw-25 {
  max-width: 25% !important; }

.vw-25 {
  width: 25vw !important; }

.mw-50 {
  max-width: 50% !important; }

.vw-50 {
  width: 50vw !important; }

.mw-75 {
  max-width: 75% !important; }

.vw-75 {
  width: 75vw !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.mw-auto {
  max-width: auto !important; }

.vw-auto {
  width: autovw !important; }

.mw-110 {
  max-width: 110% !important; }

.vw-110 {
  width: 110vw !important; }

.mw-120 {
  max-width: 120% !important; }

.vw-120 {
  width: 120vw !important; }

.mw-130 {
  max-width: 130% !important; }

.vw-130 {
  width: 130vw !important; }

.mw-140 {
  max-width: 140% !important; }

.vw-140 {
  width: 140vw !important; }

.mw-150 {
  max-width: 150% !important; }

.vw-150 {
  width: 150vw !important; }

@media (min-width: 576px) {
  .h-sm-25 {
    height: 25% !important; }
  .w-sm-25 {
    width: 25% !important; }
  .mw-sm-25 {
    max-width: 25% !important; }
  .vh-sm-25 {
    height: 25vh; }
  .vw-sm-25 {
    width: 25vw; }
  .h-sm-50 {
    height: 50% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .mw-sm-50 {
    max-width: 50% !important; }
  .vh-sm-50 {
    height: 50vh; }
  .vw-sm-50 {
    width: 50vw; }
  .h-sm-75 {
    height: 75% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .mw-sm-75 {
    max-width: 75% !important; }
  .vh-sm-75 {
    height: 75vh; }
  .vw-sm-75 {
    width: 75vw; }
  .h-sm-100 {
    height: 100% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .mw-sm-100 {
    max-width: 100% !important; }
  .vh-sm-100 {
    height: 100vh; }
  .vw-sm-100 {
    width: 100vw; }
  .h-sm-auto {
    height: auto !important; }
  .w-sm-auto {
    width: auto !important; }
  .mw-sm-auto {
    max-width: auto !important; }
  .vh-sm-auto {
    height: autovh; }
  .vw-sm-auto {
    width: autovw; }
  .h-sm-110 {
    height: 110% !important; }
  .w-sm-110 {
    width: 110% !important; }
  .mw-sm-110 {
    max-width: 110% !important; }
  .vh-sm-110 {
    height: 110vh; }
  .vw-sm-110 {
    width: 110vw; }
  .h-sm-120 {
    height: 120% !important; }
  .w-sm-120 {
    width: 120% !important; }
  .mw-sm-120 {
    max-width: 120% !important; }
  .vh-sm-120 {
    height: 120vh; }
  .vw-sm-120 {
    width: 120vw; }
  .h-sm-130 {
    height: 130% !important; }
  .w-sm-130 {
    width: 130% !important; }
  .mw-sm-130 {
    max-width: 130% !important; }
  .vh-sm-130 {
    height: 130vh; }
  .vw-sm-130 {
    width: 130vw; }
  .h-sm-140 {
    height: 140% !important; }
  .w-sm-140 {
    width: 140% !important; }
  .mw-sm-140 {
    max-width: 140% !important; }
  .vh-sm-140 {
    height: 140vh; }
  .vw-sm-140 {
    width: 140vw; }
  .h-sm-150 {
    height: 150% !important; }
  .w-sm-150 {
    width: 150% !important; }
  .mw-sm-150 {
    max-width: 150% !important; }
  .vh-sm-150 {
    height: 150vh; }
  .vw-sm-150 {
    width: 150vw; } }

@media (min-width: 768px) {
  .h-md-25 {
    height: 25% !important; }
  .w-md-25 {
    width: 25% !important; }
  .mw-md-25 {
    max-width: 25% !important; }
  .vh-md-25 {
    height: 25vh; }
  .vw-md-25 {
    width: 25vw; }
  .h-md-50 {
    height: 50% !important; }
  .w-md-50 {
    width: 50% !important; }
  .mw-md-50 {
    max-width: 50% !important; }
  .vh-md-50 {
    height: 50vh; }
  .vw-md-50 {
    width: 50vw; }
  .h-md-75 {
    height: 75% !important; }
  .w-md-75 {
    width: 75% !important; }
  .mw-md-75 {
    max-width: 75% !important; }
  .vh-md-75 {
    height: 75vh; }
  .vw-md-75 {
    width: 75vw; }
  .h-md-100 {
    height: 100% !important; }
  .w-md-100 {
    width: 100% !important; }
  .mw-md-100 {
    max-width: 100% !important; }
  .vh-md-100 {
    height: 100vh; }
  .vw-md-100 {
    width: 100vw; }
  .h-md-auto {
    height: auto !important; }
  .w-md-auto {
    width: auto !important; }
  .mw-md-auto {
    max-width: auto !important; }
  .vh-md-auto {
    height: autovh; }
  .vw-md-auto {
    width: autovw; }
  .h-md-110 {
    height: 110% !important; }
  .w-md-110 {
    width: 110% !important; }
  .mw-md-110 {
    max-width: 110% !important; }
  .vh-md-110 {
    height: 110vh; }
  .vw-md-110 {
    width: 110vw; }
  .h-md-120 {
    height: 120% !important; }
  .w-md-120 {
    width: 120% !important; }
  .mw-md-120 {
    max-width: 120% !important; }
  .vh-md-120 {
    height: 120vh; }
  .vw-md-120 {
    width: 120vw; }
  .h-md-130 {
    height: 130% !important; }
  .w-md-130 {
    width: 130% !important; }
  .mw-md-130 {
    max-width: 130% !important; }
  .vh-md-130 {
    height: 130vh; }
  .vw-md-130 {
    width: 130vw; }
  .h-md-140 {
    height: 140% !important; }
  .w-md-140 {
    width: 140% !important; }
  .mw-md-140 {
    max-width: 140% !important; }
  .vh-md-140 {
    height: 140vh; }
  .vw-md-140 {
    width: 140vw; }
  .h-md-150 {
    height: 150% !important; }
  .w-md-150 {
    width: 150% !important; }
  .mw-md-150 {
    max-width: 150% !important; }
  .vh-md-150 {
    height: 150vh; }
  .vw-md-150 {
    width: 150vw; } }

@media (min-width: 992px) {
  .h-lg-25 {
    height: 25% !important; }
  .w-lg-25 {
    width: 25% !important; }
  .mw-lg-25 {
    max-width: 25% !important; }
  .vh-lg-25 {
    height: 25vh; }
  .vw-lg-25 {
    width: 25vw; }
  .h-lg-50 {
    height: 50% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .mw-lg-50 {
    max-width: 50% !important; }
  .vh-lg-50 {
    height: 50vh; }
  .vw-lg-50 {
    width: 50vw; }
  .h-lg-75 {
    height: 75% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .mw-lg-75 {
    max-width: 75% !important; }
  .vh-lg-75 {
    height: 75vh; }
  .vw-lg-75 {
    width: 75vw; }
  .h-lg-100 {
    height: 100% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .mw-lg-100 {
    max-width: 100% !important; }
  .vh-lg-100 {
    height: 100vh; }
  .vw-lg-100 {
    width: 100vw; }
  .h-lg-auto {
    height: auto !important; }
  .w-lg-auto {
    width: auto !important; }
  .mw-lg-auto {
    max-width: auto !important; }
  .vh-lg-auto {
    height: autovh; }
  .vw-lg-auto {
    width: autovw; }
  .h-lg-110 {
    height: 110% !important; }
  .w-lg-110 {
    width: 110% !important; }
  .mw-lg-110 {
    max-width: 110% !important; }
  .vh-lg-110 {
    height: 110vh; }
  .vw-lg-110 {
    width: 110vw; }
  .h-lg-120 {
    height: 120% !important; }
  .w-lg-120 {
    width: 120% !important; }
  .mw-lg-120 {
    max-width: 120% !important; }
  .vh-lg-120 {
    height: 120vh; }
  .vw-lg-120 {
    width: 120vw; }
  .h-lg-130 {
    height: 130% !important; }
  .w-lg-130 {
    width: 130% !important; }
  .mw-lg-130 {
    max-width: 130% !important; }
  .vh-lg-130 {
    height: 130vh; }
  .vw-lg-130 {
    width: 130vw; }
  .h-lg-140 {
    height: 140% !important; }
  .w-lg-140 {
    width: 140% !important; }
  .mw-lg-140 {
    max-width: 140% !important; }
  .vh-lg-140 {
    height: 140vh; }
  .vw-lg-140 {
    width: 140vw; }
  .h-lg-150 {
    height: 150% !important; }
  .w-lg-150 {
    width: 150% !important; }
  .mw-lg-150 {
    max-width: 150% !important; }
  .vh-lg-150 {
    height: 150vh; }
  .vw-lg-150 {
    width: 150vw; } }

@media (min-width: 1200px) {
  .h-xl-25 {
    height: 25% !important; }
  .w-xl-25 {
    width: 25% !important; }
  .mw-xl-25 {
    max-width: 25% !important; }
  .vh-xl-25 {
    height: 25vh; }
  .vw-xl-25 {
    width: 25vw; }
  .h-xl-50 {
    height: 50% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .mw-xl-50 {
    max-width: 50% !important; }
  .vh-xl-50 {
    height: 50vh; }
  .vw-xl-50 {
    width: 50vw; }
  .h-xl-75 {
    height: 75% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .mw-xl-75 {
    max-width: 75% !important; }
  .vh-xl-75 {
    height: 75vh; }
  .vw-xl-75 {
    width: 75vw; }
  .h-xl-100 {
    height: 100% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .mw-xl-100 {
    max-width: 100% !important; }
  .vh-xl-100 {
    height: 100vh; }
  .vw-xl-100 {
    width: 100vw; }
  .h-xl-auto {
    height: auto !important; }
  .w-xl-auto {
    width: auto !important; }
  .mw-xl-auto {
    max-width: auto !important; }
  .vh-xl-auto {
    height: autovh; }
  .vw-xl-auto {
    width: autovw; }
  .h-xl-110 {
    height: 110% !important; }
  .w-xl-110 {
    width: 110% !important; }
  .mw-xl-110 {
    max-width: 110% !important; }
  .vh-xl-110 {
    height: 110vh; }
  .vw-xl-110 {
    width: 110vw; }
  .h-xl-120 {
    height: 120% !important; }
  .w-xl-120 {
    width: 120% !important; }
  .mw-xl-120 {
    max-width: 120% !important; }
  .vh-xl-120 {
    height: 120vh; }
  .vw-xl-120 {
    width: 120vw; }
  .h-xl-130 {
    height: 130% !important; }
  .w-xl-130 {
    width: 130% !important; }
  .mw-xl-130 {
    max-width: 130% !important; }
  .vh-xl-130 {
    height: 130vh; }
  .vw-xl-130 {
    width: 130vw; }
  .h-xl-140 {
    height: 140% !important; }
  .w-xl-140 {
    width: 140% !important; }
  .mw-xl-140 {
    max-width: 140% !important; }
  .vh-xl-140 {
    height: 140vh; }
  .vw-xl-140 {
    width: 140vw; }
  .h-xl-150 {
    height: 150% !important; }
  .w-xl-150 {
    width: 150% !important; }
  .mw-xl-150 {
    max-width: 150% !important; }
  .vh-xl-150 {
    height: 150vh; }
  .vw-xl-150 {
    width: 150vw; } }

@media (min-width: 576px) {
  .w-cover {
    width: calc(100% + (100vw - 540px) / 2 + 40px); } }

@media (min-width: 768px) {
  .w-cover {
    width: calc(100% + (100vw - 720px) / 2 + 40px); } }

@media (min-width: 992px) {
  .w-cover {
    width: calc(100% + (100vw - 960px) / 2 + 40px); } }

@media (min-width: 1200px) {
  .w-cover {
    width: calc(100% + (100vw - 1040px) / 2 + 40px); } }

.font-size-sm {
  font-size: 0.9375rem !important; }

.font-size-lg {
  font-size: 1.1875rem !important; }
  @media (max-width: 991.98px) {
    .font-size-lg {
      font-size: 1.2625rem !important; } }

.text-gray-100 {
  color: #F9FBFD !important; }

.text-gray-200 {
  color: #F1F4F8 !important; }

.text-gray-300 {
  color: #D9E2EF !important; }

.text-gray-400 {
  color: #C6D3E6 !important; }

.text-gray-500 {
  color: #ABBCD5 !important; }

.text-gray-600 {
  color: #869AB8 !important; }

.text-gray-700 {
  color: #506690 !important; }

.text-gray-800 {
  color: #384C74 !important; }

.text-gray-900 {
  color: #1B2A4E !important; }

.text-white-70 {
  color: rgba(255, 255, 255, 0.7) !important; }

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important; }

.text-white-80 {
  color: rgba(255, 255, 255, 0.8) !important; }

.text-muted-80 {
  color: rgba(134, 154, 184, 0.8) !important; }

.letter-spacing-lg {
  letter-spacing: 0.08em !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-1 {
  opacity: 1 !important; }

[data-aos="img-skewed-item-left"],
[data-aos="img-skewed-item-right"] {
  opacity: 0;
  transition-property: opacity, transform;
  will-change: opacity, transform, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  [data-aos="img-skewed-item-left"].aos-animate,
  [data-aos="img-skewed-item-right"].aos-animate {
    opacity: 1; }

[data-aos="img-skewed-item-left"] {
  transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0); }
  [data-aos="img-skewed-item-left"].aos-animate {
    transform: rotateY(-35deg) rotateX(15deg) translate3d(0, 0, 0); }

[data-aos="img-skewed-item-right"] {
  transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0); }
  [data-aos="img-skewed-item-right"].aos-animate {
    transform: rotateY(35deg) rotateX(15deg) translate3d(0, 0, 0); }

.avatar {
  position: relative;
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 0.625rem; }
  .avatar:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(/static/media/avatar-status.1c606666.svg), url(/static/media/avatar-group.edea3b7a.svg), url(/static/media/avatar-group-hover.2599daf7.svg), url(/static/media/avatar-group-hover-last.91ed6a1f.svg); }

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ABBCD5;
  color: #FFFFFF; }

.avatar-online::before,
.avatar-offline::before {
  content: '';
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border-radius: 50%; }

.avatar-online .avatar-img,
.avatar-offline .avatar-img {
  -webkit-mask-image: url(/static/media/avatar-status.1c606666.svg);
          mask-image: url(/static/media/avatar-status.1c606666.svg);
  -webkit-mask-size: 100% 100%;
          mask-size: 100% 100%; }

.avatar-online::before {
  background-color: #42BA96; }

.avatar-offline::before {
  background-color: #ABBCD5; }

.avatar-xs {
  width: 1rem;
  height: 1rem;
  font-size: 0.33333rem; }

.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.5rem; }

.avatar-lg {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem; }

.avatar-xl {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem; }
  @media (min-width: 768px) {
    .avatar-xl {
      width: 4rem;
      height: 4rem;
      font-size: 1.33333rem; } }

.avatar-xxl {
  width: 4rem;
  height: 4rem;
  font-size: 1.33333rem; }
  @media (min-width: 768px) {
    .avatar-xxl {
      width: 5rem;
      height: 5rem;
      font-size: 1.66667rem; } }

.avatar.avatar-4by3 {
  width: 2.5rem; }

.avatar-xs.avatar-4by3 {
  width: 1.33333rem; }

.avatar-sm.avatar-4by3 {
  width: 2rem; }

.avatar-lg.avatar-4by3 {
  width: 3rem; }

.avatar-xl.avatar-4by3 {
  width: 5.33333rem; }

.avatar-xxl.avatar-4by3 {
  width: 6.66667rem; }

.avatar-group {
  display: inline-flex; }
  .avatar-group .avatar + .avatar {
    margin-left: -0.46875rem; }
  .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -0.25rem; }
  .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -0.375rem; }
  .avatar-group .avatar-lg + .avatar-lg {
    margin-left: -0.5625rem; }
  .avatar-group .avatar-xl + .avatar-xl {
    margin-left: -1rem; }
  .avatar-group .avatar-xxl + .avatar-xxl {
    margin-left: -1.25rem; }
  .avatar-group .avatar:not(:last-child) {
    -webkit-mask-image: url(/static/media/avatar-group.edea3b7a.svg);
            mask-image: url(/static/media/avatar-group.edea3b7a.svg);
    -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%; }
  .avatar-group .avatar:hover {
    -webkit-mask-image: none;
            mask-image: none;
    z-index: 1; }
    .avatar-group .avatar:hover + .avatar {
      -webkit-mask-image: url(/static/media/avatar-group-hover.2599daf7.svg);
              mask-image: url(/static/media/avatar-group-hover.2599daf7.svg);
      -webkit-mask-size: 100% 100%;
              mask-size: 100% 100%; }
      .avatar-group .avatar:hover + .avatar:last-child {
        -webkit-mask-image: url(/static/media/avatar-group-hover-last.91ed6a1f.svg);
                mask-image: url(/static/media/avatar-group-hover-last.91ed6a1f.svg); }

.choices::after {
  display: none; }

.choices .dropdown-menu {
  width: 100%; }

.choices .form-control-xs ~ .dropdown-menu,
.choices .custom-select-xs ~ .dropdown-menu {
  width: auto;
  min-width: 100%;
  padding: 1rem 1.25rem; }

.collapse-chevron {
  display: inline-block;
  transition: all 0.2s ease-in-out; }

[aria-expanded="true"] .collapse-chevron {
  transform: rotate(-180deg); }

.device {
  position: relative; }
  .device > .img-fluid {
    position: relative; }

.device::before {
  content: "";
  background-color: #F9FBFD; }

.device::before,
.device-screen {
  position: absolute;
  object-fit: cover; }

.device-iphonex::before,
.device-iphonex > .device-screen {
  top: 7.784431138%;
  left: 16.4021164%;
  width: 66.137566137%;
  height: 80.838323353%; }

.device-macbook::before,
.device-macbook > .device-screen {
  top: 11.53846154%;
  left: 13.38709677%;
  width: 73.548387096%;
  height: 73.076923076%; }

.device-combo {
  position: relative; }

.device-combo > .device {
  position: absolute; }

.device-combo-iphonex-iphonex {
  padding-bottom: 130.250482%; }

.device-combo-iphonex-iphonex > .device-iphonex:first-child {
  bottom: 0;
  left: 0;
  width: 65.5260116%;
  z-index: 1; }

.device-combo-iphonex-iphonex > .device-iphonex:last-child {
  top: 0;
  right: 0;
  width: 72.8323699%; }

.device-combo-macbook-iphonex,
.device-combo-iphonex-macbook {
  padding-bottom: 62.4260355%; }

.device-combo-macbook-iphonex > .device-macbook,
.device-combo-iphonex-macbook > .device-macbook {
  width: 91.7159763%; }

.device-combo-macbook-iphonex > .device-iphonex,
.device-combo-iphonex-macbook > .device-iphonex {
  width: 27.9585799%;
  z-index: 1; }

.device-combo-macbook-iphonex > .device-macbook {
  top: 0;
  left: 0; }

.device-combo-iphonex-macbook > .device-macbook {
  top: 0;
  right: 0; }

.device-combo-macbook-iphonex > .device-iphonex {
  bottom: 0;
  right: 0; }

.device-combo-iphonex-macbook > .device-iphonex {
  bottom: 0;
  left: 0; }

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column; }

.dz-message {
  padding: 5rem 1rem;
  background-color: #FFFFFF;
  border: 1px dashed #C6D3E6;
  border-radius: 0.375rem;
  text-align: center;
  color: #869AB8;
  transition: all 0.2s ease-in-out;
  order: -1;
  cursor: pointer;
  z-index: 999; }
  .dz-message:hover {
    border-color: #869AB8;
    color: #161C2D; }

.dz-button {
  background: none;
  border: 0;
  font-size: inherit;
  color: inherit; }

.dz-drag-hover .dz-message {
  border-color: #335EEA;
  color: #335EEA; }

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(22, 28, 45, 0.9);
  color: white;
  opacity: 0; }
  .dropzone-single.dz-max-files-reached .dz-message:hover {
    opacity: 1; }

.dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.375rem; }

.dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.375rem; }

.dz-preview-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem; }

.dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;
  border-bottom: 0; }

[data-dz-size] strong {
  font-weight: 400; }

body.compensate-for-scrollbar {
  margin-right: 0 !important; }

.fancybox-container .fancybox-bg {
  background-color: rgba(255, 255, 255, 0.97); }

.fe-lg {
  font-size: 1.3125rem; }
  @media (min-width: 768px) {
    .fe-lg {
      font-size: 1.125rem; } }

.flickity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(1.6em + 1.625rem + 2px);
  height: calc(1.6em + 1.625rem + 2px);
  background-color: #335EEA;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.05);
  color: #FFFFFF; }
  .flickity-button::before, .flickity-button::after {
    font-family: "Feather"; }
  .flickity-button:hover, .flickity-button:focus {
    background-color: #335EEA;
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1); }

.flickity-button.previous {
  left: 0;
  transform: translate(calc(-50% + 10px), -50%); }
  @media (min-width: 768px) {
    .flickity-button.previous {
      transform: translate(-50%, -50%); } }
  .flickity-button.previous::before {
    content: "\E910"; }

.flickity-button.next {
  right: 0;
  transform: translate(calc(50% - 10px), -50%); }
  @media (min-width: 768px) {
    .flickity-button.next {
      transform: translate(50%, -50%); } }
  .flickity-button.next::before {
    content: "\E912"; }

.flickity-button-icon {
  display: none; }

.flickity-button-white .flickity-button {
  background-color: #FFFFFF;
  color: #335EEA; }

.flickity-button-bottom .flickity-button {
  top: auto;
  bottom: 1.5rem;
  transform: none; }

.flickity-button-bottom .flickity-button.previous {
  left: auto;
  right: 6.375rem; }

.flickity-button-bottom .flickity-button.next {
  left: auto;
  right: 2.5rem; }

.flickity-button-inset .flickity-button {
  transform: translateY(-50%); }

.flickity-button-inset .flickity-button.previous {
  left: 1.5rem; }

.flickity-button-inset .flickity-button.next {
  right: 1.5rem; }

.flickity-viewport-visible .flickity-viewport {
  overflow: visible; }

[data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
  transition: all .5s ease; }

.bg-dark .footer-brand {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1); }

.hljs {
  background-color: transparent; }

.icon > svg {
  width: 3rem;
  height: 3rem; }

.icon[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important; }

.icon-xs > svg {
  width: 1.5rem;
  height: 1.5rem; }

.icon-sm > svg {
  width: 2.25rem;
  height: 2.25rem; }

.icon-lg > svg {
  width: 4rem;
  height: 4rem; }

.icon-xl > svg {
  width: 5rem;
  height: 5rem; }

.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4.1875rem;
  height: 4.1875rem;
  border-radius: 50%; }
  .icon-circle > .fe {
    font-size: 1.875rem; }

.list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none; }

.list-item + .list-item {
  margin-top: 0.35rem; }

.list-link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 0.9375rem;
  color: #506690; }
  .list-link:hover, .list-link:focus {
    text-decoration: none;
    color: #335EEA; }

.ql-container {
  font-family: "HKGroteskPro", sans-serif; }

.ql-toolbar {
  position: relative;
  padding: 0.8125rem 1.25rem;
  background-color: #FFFFFF;
  border: 1px solid #F1F4F8;
  border-radius: 0.375rem 0.375rem 0 0;
  color: #161C2D; }

.ql-toolbar + .ql-container {
  margin-top: -1px; }

.ql-toolbar + .ql-container .ql-editor {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.ql-editor {
  min-height: 6.8rem;
  display: block;
  width: 100%;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  line-height: 1.6;
  color: #161C2D;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #F1F4F8;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .ql-editor {
      transition: none; } }
  .ql-editor::-ms-expand {
    background-color: transparent;
    border: 0; }
  .ql-editor:focus {
    color: #161C2D;
    background-color: #FFFFFF;
    border-color: #a7b9f6;
    outline: 0; }

.ql-hidden {
  position: absolute;
  transform: scale(0); }

.ql-editor.ql-blank::before {
  top: 0.8125rem;
  left: 1.25rem;
  font-style: normal;
  color: #869AB8; }

.ql-editor:focus::before {
  display: none; }

.ql-formats {
  padding-left: .5rem;
  padding-right: .5rem; }
  .ql-formats:first-child {
    padding-left: 0; }
  .ql-formats:last-child {
    padding-right: 0; }

.ql-toolbar button {
  padding: 0 .25rem;
  background: none;
  border: none;
  color: #161C2D;
  cursor: pointer;
  transition: all 0.2s ease-in-out; }
  .ql-toolbar button:hover {
    color: #335EEA; }
  .ql-toolbar button:first-child {
    margin-left: -.25rem; }

.ql-toolbar .ql-active {
  color: #335EEA; }

.ql-toolbar button svg {
  height: 1.1875rem;
  width: 1.1875rem; }

.ql-toolbar .ql-stroke {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none; }

.ql-toolbar .ql-thin {
  stroke-width: 1; }

.ql-toolbar .ql-fill {
  fill: currentColor; }

.ql-toolbar input.ql-image {
  position: absolute;
  transform: scale(0); }

.ql-tooltip {
  position: absolute;
  z-index: 1070;
  display: flex;
  flex-wrap: nowrap;
  width: 18.5rem;
  background-color: #FFFFFF;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  padding: 0.8125rem 1.25rem;
  margin-top: .6rem;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1); }
  .ql-tooltip:before, .ql-tooltip:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%); }
  .ql-tooltip:before {
    border-bottom: 0.6rem solid transparent;
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent; }
  .ql-tooltip:after {
    border-bottom: 0.5rem solid #FFFFFF;
    border-left: .5rem solid transparent;
    border-right: .5rem solid transparent; }

.ql-container .ql-tooltip:hover {
  display: flex !important; }

.ql-tooltip .ql-preview {
  width: 100%;
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .ql-tooltip .ql-preview {
      transition: none; } }

.ql-tooltip.ql-editing .ql-preview {
  display: none; }

.ql-tooltip input {
  display: none;
  width: 100%;
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.6;
  border: none;
  color: #161C2D; }
  .ql-tooltip input:focus {
    outline: none; }

.ql-tooltip.ql-editing input {
  display: block; }

.ql-tooltip .ql-action,
.ql-tooltip .ql-remove {
  margin-left: .25rem; }

.ql-tooltip .ql-action::before,
.ql-tooltip .ql-remove::before {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0.5625rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .ql-tooltip .ql-action::before,
    .ql-tooltip .ql-remove::before {
      transition: none; } }
  .ql-tooltip .ql-action::before:hover, .ql-tooltip .ql-action::before:focus,
  .ql-tooltip .ql-remove::before:hover,
  .ql-tooltip .ql-remove::before:focus {
    text-decoration: none; }
  .ql-tooltip .ql-action::before:focus, .ql-tooltip .ql-action::before.focus,
  .ql-tooltip .ql-remove::before:focus,
  .ql-tooltip .ql-remove::before.focus {
    outline: 0;
    box-shadow: none; }

.ql-tooltip .ql-action::before,
.ql-tooltip.ql-editing .ql-action::before {
  color: #FFFFFF;
  background-color: #335EEA;
  border-color: #335EEA;
  box-shadow: none; }
  .ql-tooltip .ql-action::before:hover,
  .ql-tooltip.ql-editing .ql-action::before:hover {
    color: #FFFFFF;
    background-color: #1746e0;
    border-color: #1643d4; }
  .ql-tooltip .ql-action::before:focus, .ql-tooltip .ql-action::before.focus,
  .ql-tooltip.ql-editing .ql-action::before:focus,
  .ql-tooltip.ql-editing .ql-action::before.focus {
    color: #FFFFFF;
    background-color: #1746e0;
    border-color: #1643d4;
    box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5); }
  .ql-tooltip .ql-action::before.disabled, .ql-tooltip .ql-action::before:disabled,
  .ql-tooltip.ql-editing .ql-action::before.disabled,
  .ql-tooltip.ql-editing .ql-action::before:disabled {
    color: #FFFFFF;
    background-color: #335EEA;
    border-color: #335EEA; }
  .ql-tooltip .ql-action::before:not(:disabled):not(.disabled):active, .ql-tooltip .ql-action::before:not(:disabled):not(.disabled).active,
  .show > .ql-tooltip .ql-action::before.dropdown-toggle,
  .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled):active,
  .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled).active,
  .show >
  .ql-tooltip.ql-editing .ql-action::before.dropdown-toggle {
    color: #FFFFFF;
    background-color: #1643d4;
    border-color: #153fc9; }
    .ql-tooltip .ql-action::before:not(:disabled):not(.disabled):active:focus, .ql-tooltip .ql-action::before:not(:disabled):not(.disabled).active:focus,
    .show > .ql-tooltip .ql-action::before.dropdown-toggle:focus,
    .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled):active:focus,
    .ql-tooltip.ql-editing .ql-action::before:not(:disabled):not(.disabled).active:focus,
    .show >
    .ql-tooltip.ql-editing .ql-action::before.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(82, 118, 237, 0.5); }

.ql-tooltip .ql-action::before {
  content: 'Edit'; }

.ql-tooltip.ql-editing .ql-action::before {
  content: 'Save'; }

.ql-tooltip .ql-remove::before {
  color: #1B2A4E;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  box-shadow: none;
  content: 'Remove';
  border-color: #D9E2EF; }
  .ql-tooltip .ql-remove::before:hover {
    color: #1B2A4E;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .ql-tooltip .ql-remove::before:focus, .ql-tooltip .ql-remove::before.focus {
    color: #1B2A4E;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5); }
  .ql-tooltip .ql-remove::before.disabled, .ql-tooltip .ql-remove::before:disabled {
    color: #1B2A4E;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled):active, .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled).active,
  .show > .ql-tooltip .ql-remove::before.dropdown-toggle {
    color: #1B2A4E;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled):active:focus, .ql-tooltip .ql-remove::before:not(:disabled):not(.disabled).active:focus,
    .show > .ql-tooltip .ql-remove::before.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(221, 223, 228, 0.5); }

.ql-tooltip.ql-editing .ql-remove::before {
  display: none; }

.ql-editor blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.ql-editor img {
  max-width: 100%;
  height: auto; }

.screenshot {
  border-radius: 0.625rem;
  box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1), 16px 40px 75px -40px rgba(0, 0, 0, 0.2); }

.section-border {
  position: relative; }

.section-border::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-width: 2px 0 0 0;
  border-style: solid;
  border-color: inherit;
  content: ""; }

.shape {
  position: absolute;
  pointer-events: none; }

.shape > * {
  display: block; }

.shape:not([class*="shape-blur"]) {
  overflow: hidden; }

.shape:not([class*="shape-blur"]) > * {
  transform: scale(2); }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .shape > svg {
    display: none; } }

.shape-top {
  top: 0;
  right: 0;
  left: 0; }

.shape-top > * {
  transform-origin: bottom center; }

.shape-right {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.shape-right > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  right: inherit;
  transform-origin: center left; }

.shape-bottom {
  right: 0;
  bottom: 0;
  left: 0; }

.shape-bottom > * {
  transform-origin: top center; }

.shape-bottom-100 {
  right: 0;
  bottom: 100%;
  left: 0; }

.shape-bottom-100 > * {
  transform-origin: top center; }

.shape-left {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.shape-left > * {
  position: inherit;
  top: inherit;
  bottom: inherit;
  left: inherit;
  transform-origin: center right; }

.shape-fluid-x > * {
  width: 100%;
  height: auto; }

.shape-fluid-y > * {
  width: auto;
  height: 100%; }

.shape-flip-x {
  transform: scaleX(-1); }

.shape-blur-1 {
  top: 0;
  right: 0;
  left: 0; }
  @media (min-width: 768px) {
    .shape-blur-1 {
      bottom: 0;
      left: 100%;
      right: auto; } }

.shape-blur-1 > * {
  width: 100%;
  height: auto; }
  @media (min-width: 768px) {
    .shape-blur-1 > * {
      width: auto;
      height: 100%; } }

.shape-blur-2 {
  top: 0;
  right: 0;
  left: 0; }
  @media (min-width: 768px) {
    .shape-blur-2 {
      bottom: 0;
      right: 50%;
      left: auto; } }

.shape-blur-2 > * {
  width: 100%;
  height: auto; }
  @media (min-width: 768px) {
    .shape-blur-2 > * {
      width: auto;
      height: 100%;
      float: right; } }

.shape-blur-3 {
  top: 0;
  right: 0;
  left: 0;
  min-height: 100%; }

.shape-blur-3 > * {
  height: 100%;
  width: 100%; }

.shape-blur-4 {
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) scale(1.3); }

.shape-blur-4 > * {
  width: auto;
  height: 100%;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 992px) {
  .sidenav {
    position: fixed;
    top: 84px;
    height: calc(100vh - 84px);
    overflow: auto; } }

@media (min-width: 992px) {
  .sidenav-left {
    left: 0; } }

@media (min-width: 992px) {
  .sidenav-right {
    right: 0; } }

h1, h2 {
  font-family: 'EB Garamond';
  font-weight: bold; }

.display-1 {
  font-family: 'EB Garamond' !important; }

h3, h4, h5, p {
  font-family: 'HKGroteskPro'; }

.bg-blue {
  background-color: #354178; }

.text-hectiqblue {
  color: #354178; }

strong {
  font-weight: 800 !important; }

.bg-grad {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)) !important;
  padding-bottom: 20px; }

.btn-primary {
  background-color: #354178; }
  .btn-primary:hover {
    background-color: #354178;
    opacity: 0.8;
    transition: all 0.3s; }

.nav-link :not(.circle):hover {
  opacity: 0.5;
  transition: all 0.3; }

.nav-link span.circle {
  border-radius: 50%;
  padding: 0.4em;
  border: 1.7px white solid; }
  .nav-link span.circle:hover {
    background-color: white;
    color: #354178;
    transition: all 0.3s; }
  .nav-link span.circle.dark {
    border: 1.7px #354178 solid; }
    .nav-link span.circle.dark:hover {
      background-color: #354178;
      color: white;
      transition: all 0.3s; }

a.nohover-link:hover {
  color: inherit !important; }

.proud-quote a {
  color: white !important;
  font-weight: 600 !important; }

html {
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
body,
input,
select,
button,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /*font-size: 20px;*/
  line-height: 1.5;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

dl,
dt,
dd {
  margin: 0;
}

a,
a:hover {
  text-decoration: none;
}

strong {
  font-weight: 500;
}


p {
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
}

li {
}

input, textarea {
  -webkit-appearance: none;
}

@media (min-width: 480px) {
  body {
    /*font-size: 20px;*/
  }
}

@media (min-width: 960px) {
  body {
    /*font-size: 16px;*/
  }

  p {
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  body {
    /*font-size: 18px;*/
  }

  p {
    margin-bottom: 30px;
  }

  li {
    margin-bottom: 25px;
  }
}

