//
// feather.scss
// Theme component
//

// Sizing

.fe-lg {
  font-size: $h3-font-size;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size-md;
  }
}