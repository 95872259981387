.container-xs {
	max-width: 500px;
}

.container-sm {
	max-width: 900px;
}

.container-md {
	max-width: 1200px;
}

.container-lg {
	max-width: 1400px;
}

.container-xl {
	max-width: 2000px;
}

.container-xll {
	max-width: 2500px;
}


// Slider
.StepRangeSlider {
}
.StepRangeSlider__tooltip {
	visibility: hidden !important;
}
.StepRangeSlider__track {
	background-color: #f0f0f0;
	border: none;
	height: 8px;
}
.StepRangeSlider__thumb {
	background-color: #bdbdbd;
	border: 2px solid white;
	width: 20px;
	height: 20px;
}
.StepRangeSlider__thumb:active {
  background-color: #3182bd;
}